import { privateApi } from "utils/api"

/**
 * Get a dashboard
 * @param {object} body
 */

const getDashboards = async () => {
  try {
    const response = privateApi().get("/powerbi/dashboardReport")
    return response
  } catch (e) {}
}

export { getDashboards }
