import React, { useContext } from "react"
import "twin.macro"
import { EditorPanelHeader } from "../editor/Header"
import AssignTrackers from "app/AssignTrackers"
import { DeviceContext } from "app/Device/context"
import { AlarmsContext } from "routes/Alarms/context"
import { AlarmActions, AlarmTypes } from "routes/Alarms/actions"
import { IDevice, IDeviceGroup } from "app/Device/types"

type AlarmDevicesProps = {}

export const AlarmDevices: React.FC<AlarmDevicesProps> = ({
  onSave,
  ...props
}) => {
  const {
    state: { devices, deviceGroups },
  } = useContext(DeviceContext)
  const {
    state: { editAlarm },
    dispatch,
  } = useContext(AlarmsContext)

  const onToggleAssignmentHandler = (args: [IDevice[], IDeviceGroup[]]) => {
    const [assignedDevices, assignedGroups] = args
    if (editAlarm) {
      onSave({
        deviceIDs: assignedDevices.map((x) => x.id.toString()),
        deviceGroupIDs: assignedGroups.map((x) => x.id.toString()),
      })
    }
  }

  const onBackHandler = () => {
    dispatch(
      AlarmActions(AlarmTypes.SetCurrentEditorPanel, {
        panel: null,
      })
    )
  }

  console.log(deviceGroups)

  return (
    <>
      <EditorPanelHeader onBack={onBackHandler}>
        Tildel alarm til enheder
      </EditorPanelHeader>
      <AssignTrackers
        availableGroups={deviceGroups}
        availableTrackers={devices}
        assignedGroups={deviceGroups.filter((group) =>
          editAlarm?.deviceGroupIDs?.includes(group.id.toString())
        )}
        assignedTrackers={devices.filter((device) =>
          editAlarm?.deviceIDs?.includes(device.id.toString())
        )}
        onToggleAssignment={onToggleAssignmentHandler}
        hideTitle
      />
    </>
  )
}
