import { privateApi } from "utils/api"

/**
 * Post / Create service entry by tracker ID
 * @param {number} id tracker ID
 * @param {object} body post body for service entry
 */

const createServiceEntry = async (id, body) => {
  try {
    const response = privateApi().post(`servicelog/${id}`, body)
    return response
  } catch (err) {
    console.log(err)
  }
}

/**
 * Patch / Update service entry by log ID
 * @param {number} id log ID
 * @param {object} body patch body for service entry
 */

const updateServiceEntry = async (id, body) => {
  try {
    const response = privateApi().patch(`servicelog/${id}`, body)
    return response
  } catch (err) {
    console.log(err)
  }
}

/**
 * Delete service entry by id
 * @param {number} id service entry id
 */

const deleteServiceEntry = async (id) => {
  try {
    const response = privateApi().delete(`servicelog/${id}`)
    return response
  } catch (err) {
    console.log(err)
  }
}

export { createServiceEntry, updateServiceEntry, deleteServiceEntry }
