import React, {
  useLayoutEffect,
  useMemo,
  useContext,
  useRef,
  useEffect,
} from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import tw from "twin.macro"
import { Item as ListItem } from "lib/List/Item"
import TrackerStatus from "app/TrackerStatus"
import TrackerHeading from "app/TrackerHeading"
import TrackerDetails from "app/TrackerDetails"
import {
  BatteryState,
  ExternalPowerLost,
  LostGPS,
  LostGSM,
} from "app/Device/Health"
import { KeyInformation } from "app/Device/Widget/KeyInformation"
import { useTrackers } from "./hooks"
import { useWindowActive } from "hooks/useWindowActive"
import { DeviceContext } from "app/Device/context"
import { DeviceActions, DeviceTypes } from "app/Device/actions"
import { DisplayKeyEnum } from "app/TrackerKPI/kpi.types"
import isEqual from "lodash-es/isEqual"

const StyledTracker = styled(ListItem)<{
  simple: boolean
  height: number
}>`
  font-size: 1.4rem;
  line-height: 1.4;
  // max-height: ${(props) => props.height}px;

  > span {
    padding: 0.5rem 0 0.5rem 2rem;
    ${(props) => (props.toggled ? tw`bg-gray-200` : ``)}
    ${(props) => props.theme.media.tablet_landscape_up`
      padding: 0.5rem 0 0.5rem 1.5rem;
    `};
  }
`

const StyledDeviceHealthContainer = styled.span`
  ${tw`flex items-center justify-end`}
`

const StyledTitle = styled.span<{ simple: boolean }>`
  display: grid;
  ${(props) =>
    props.simple
      ? `grid-template-columns: 1fr;`
      : `grid-template-columns: 2rem 1fr auto;`}

  grid-column-gap: 1rem;
  // padding: .2rem 0 .2rem 0;
`

const Tracker: React.FC<any> = (props) => {
  const {
    children,
    onClick,
    openIcon,
    closeIcon,
    simple,
    deviceID,
    groupID,
    device,
    showDeviceHealth,
    ...restProps
  } = props
  // useWhyDidYouUpdate("Tracker", props)
  const { updateAddress, isLocatedInDenmark } = useTrackers()
  const { dispatch } = useContext(DeviceContext)
  const { windowActive } = useWindowActive()
  const deviceUpdatedTimestampRef = useRef<number>(0)

  const renderTitle = () => {
    const knownPosition = !!device.position
    if (knownPosition && device) {
      return (
        <StyledTitle simple={simple}>
          {!simple && (
            <TrackerStatus status={device.status} {...device}>
              <>
                {[1, 3, 4, 6].some((num) => num === device.status) && (
                  <TrackerHeading
                    // speed={speed ? speed?.value : null}
                    speed={device.speed}
                    heading={device.heading}
                  />
                )}
              </>
            </TrackerStatus>
          )}
          <span tw="relative">
            <span>{device.name}</span>
            {device.note !== "" && (
              <small tw="block opacity-80">{device.note}</small>
            )}
          </span>
          {!simple && (
            <StyledDeviceHealthContainer>
              <BatteryState device={device} />
              <ExternalPowerLost device={device} />
              <LostGPS device={device} />
              <LostGSM device={device} />
            </StyledDeviceHealthContainer>
          )}
        </StyledTitle>
      )
    }

    return device ? (
      <StyledTitle simple={simple}>
        <TrackerStatus status={device.status} {...device} />
        <span>
          <span>{device.name}</span>
          {device.note !== "" && (
            <small tw="block opacity-80">{device.note}</small>
          )}
        </span>
        {!simple && (
          <StyledDeviceHealthContainer>
            <BatteryState device={device} />
            <ExternalPowerLost device={device} />
            <LostGPS device={device} />
            <LostGSM device={device} />
          </StyledDeviceHealthContainer>
        )}
      </StyledTitle>
    ) : null
  }

  useMemo(async () => {
    const lastUpdated = +new Date(
      device.values.find(
        (x) => x.name === DisplayKeyEnum.LastConfirmationTime
      )?.value
    )
    const lastKnownAddress = device.values.find(
      (x) => x.name === DisplayKeyEnum.Address
    )?.value
    if (
      (device.values &&
        device.toggled &&
        !simple &&
        !isEqual(lastUpdated, deviceUpdatedTimestampRef.current)) ||
      (device.toggled &&
        isEqual(lastUpdated, deviceUpdatedTimestampRef.current) &&
        ["Klik for at opdatere", ""].includes(lastKnownAddress)) ||
      (device.toggled &&
        windowActive &&
        ["Klik for at opdatere", ""].includes(lastKnownAddress))
    ) {
      const addressDisplayKey = {
        name: DisplayKeyEnum.Address,
        type: "string",
      }

      addressDisplayKey.value = !windowActive
        ? "Klik for at opdatere"
        : await updateAddress(
            device.position,
            device,
            isLocatedInDenmark(device.position.geometry.coordinates),
            false
          )

      const deviceUpdate = {
        ...device,
        values: [
          addressDisplayKey,
          ...device.values.filter((x) => x.name !== DisplayKeyEnum.Address),
        ],
      }
      dispatch(
        DeviceActions(DeviceTypes.UpdateDeviceByID, {
          device: deviceUpdate,
          id: device.id as number,
        })
      )
    }
  }, [device.toggled, simple, windowActive])

  if (!device) return null
  return useMemo(() => {
    return (
      <StyledTracker
        key={deviceID}
        title={renderTitle()}
        toggled={device.toggled}
        openIcon={openIcon}
        closeIcon={closeIcon}
        simple={simple}
        onClick={onClick}
      >
        <div>
          {device.toggled && !simple && (
            <>
              <KeyInformation device={device} />
              <TrackerDetails groupID={groupID} device={device} />
            </>
          )}
        </div>
      </StyledTracker>
    )
  }, [device])
}

export default Tracker

Tracker.defaultProps = {
  children: null,
  state: "unknown",
  name: null,
  openIcon: "chevron-right",
  closeIcon: "chevron-left",
}

Tracker.propTypes = {
  children: PropTypes.node,
  state: PropTypes.oneOf(["running", "stopped", "unknown", ""]),
  name: PropTypes.string,
}
