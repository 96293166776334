import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledNavItem = styled.li`
  a {
    display: grid;
    grid-template-columns: 2rem 1fr;
    grid-column-gap: 1.5rem;
    padding: 1rem 2rem;
    padding-left: 1.5rem;
    margin-left: 0.5rem;

    &.icon-only {
      padding-left: 2rem;
      grid-column-gap: 0;
      grid-template-columns: 2rem;
    }

    > * {
      align-self: center;
    }

    > span {
      display: block;
      line-height: 1;
    }

    svg {
      height: 2rem;
      width: 2rem;
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    display: flex;
    align-items: center;
    margin-right: 1rem;

    a {
      grid-template-columns: 1fr;
      grid-row-gap: 1.5rem;
      margin-left: 0;
      padding-left: 2rem;
      font-size: 1.4rem;
      svg {
        margin: 0 auto;
        height: 2rem;
        width: 2rem;
      }
    }
  `}

  ${(props) => props.theme.media.desktop_up`
    margin-right: 0;
    a {
      grid-template-columns: 2rem 1fr auto;
      grid-column-gap: 1rem;
      padding-left: 1.5rem;
      margin-left: 0.5rem;
      font-size: 1.6rem;

      svg {
        margin: auto;
        height: 1.6rem;
        width: 1.6rem;
      }
    }
  `}
`

function NavItem({ children, ...props }) {
  return <StyledNavItem {...props}>{children}</StyledNavItem>
}

export default NavItem

NavItem.defaultProps = {}
NavItem.propTypes = {
  children: PropTypes.node.isRequired,
}
