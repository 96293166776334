class IntervalModel {
  constructor(params) {
    this.type = `Kilometer`
    this.unit = `km`
    this.startValue = null
    this.sendNotificationAtTreshold = `95`
    this.currentValue = 0
    this.endValue = 0
    this.startDate = new Date(new Date().setHours(0, 0, 0, 0))
    this.endDate = new Date()
    this.notify = false
    this.email = ``
    this.emailMsg = ``
  }
}

export { IntervalModel }
