import React from "react"
import { FormField, ValidationInput } from "@clevertrack/shared"
import { getDefaultValidationMessagesByLang } from "lib/utils/validation"
import emailValidation from "utils/emailValidation"

const defaultValidationMessages = getDefaultValidationMessagesByLang("da")
const validationMessages = {
  ...defaultValidationMessages,
  customError: "Ups! Indtast en korrekt email.",
}

function EmailForm({ ...props }) {
  return (
    <FormField validationKey="email" label="Email">
      <ValidationInput
        required
        id="email"
        type="email"
        placeholder="Indtast email"
        customValidation={emailValidation}
        validationMessages={validationMessages}
      />
    </FormField>
  )
}

export default EmailForm

EmailForm.defaultProps = {}
EmailForm.propTypes = {}
