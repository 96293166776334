import React, {
  useContext,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
} from "react"
import styled from "styled-components"
import tw from "twin.macro"
import isEqual from "lodash-es/isEqual"
import { forceCheck } from "react-lazyload"
import { Group } from "lib/List/Group"
import { Responsive } from "@clevertrack/shared"
import Search from "app/Search"
import TrackerGroups from "app/TrackerGroups"
import TileContainer from "app/TileContainer"
import Tile from "app/Tile"
import TrackerItem from "app/TrackerItem"
import { freetextSearch } from "app/Search/helper"
import { SearchContext } from "app/Search/context"
import PopUnder from "app/PopUnder"

import { IconFlip } from "app/IconFlip"
import { useTrackerSearch } from "./hooks"
import { useTrackerGroups } from "app/TrackerGroups/hooks"
import { SizeEnum } from "theme"
import { DeviceContext } from "app/Device/context"
import { DeviceActions, DeviceTypes } from "app/Device/actions"
import { useDevices } from "app/Device/hooks"
import { AppContext } from "context"
import { GlobalAppActions, GlobalAppTypes } from "context/App/actions"
import { useDebounce } from "react-use"
import { resetSearch } from "app/Search/actions"

const StyledSearch = styled(Search)`
  ${(props) => props.theme.media.tablet_landscape_up`

    .pad {
      padding: 1rem;
    }
  `}
`

const StyledPopUnder = styled(PopUnder)`
  opacity: 1;
  z-index: 100;
  background: white;

  ${(props) =>
    props.simple
      ? `top: 0; padding-top: ${props.theme.mobileContentOffset};`
      : `${props.show ? `top: 50vh; ` : `top: 50vh;`}
        ${props.show ? "margin-top: auto" : `margin-top: 0`}`}
`

const StyledTitle = styled.span`
  position: sticky;
  bottom: ${SizeEnum.TapBarHeight};
  ${tw`flex items-center justify-between`}
`

const StyledToggle = styled.span<{ toggled: boolean }>`
  ${tw`relative flex items-center text-left text-xl m-6 ml-8 mt-6 text-gray-400 hover:text-gray-800 cursor-pointer transition-colors`}
  max-width: max-content;

  ${(props) => (props.toggled ? tw`text-gray-800` : ``)}
`

const sortFunction = (a, b) => (a.item.name > b.item.name ? 1 : -1)

const InitPhoneSearch = ({
  toggleSidebar,
  toggleOnInit,
  onReset,
  onFocus,
  ...props
}) => {
  useEffect(() => {
    if (
      window.location.pathname === "/app" ||
      window.location.pathname === "/app/"
    ) {
      if (toggleOnInit) toggleSidebar()
    }
  }, [])
  return (
    <StyledSearch
      onReset={onReset}
      sortFnc={sortFunction}
      onFocus={onFocus}
      {...props}
    />
  )
}

interface TrackerSearchProps {
  simple?: boolean
  visibilityToggle?: boolean
  placeholder?: string
  toggleOnInit?: boolean
}

const TrackerSearch: React.FC<TrackerSearchProps> = ({
  simple = false,
  visibilityToggle,
  toggleOnInit = false,
  ...props
}) => {
  const {
    state: { deviceGroupVisibilitySetupToggled, deviceGroups, syncAddress },
    dispatch,
  } = useContext(DeviceContext)
  const {
    devices,
    toggledDevices,
    subscribeToSomeDevices,
    unsubscribeToValues,
  } = useDevices(syncAddress)
  const {
    state: { results, suggestions, query },
    dispatch: searchDispatch,
  } = useContext(SearchContext)
  const {
    state: { sidebarToggled },
    dispatch: appDispatch,
  } = useContext(AppContext)
  const { getOffsetByRoute } = useTrackerSearch()
  const { invisibleTrackerGroups } = useTrackerGroups()

  function toggleSidebar(toggle?: boolean) {
    if (toggle) {
      appDispatch(
        GlobalAppActions(GlobalAppTypes.ToggleSidebar, { toggled: toggle })
      )
    } else {
      appDispatch(
        GlobalAppActions(GlobalAppTypes.ToggleSidebar, {
          toggled: !sidebarToggled,
        })
      )
    }
  }

  function onToggleVisibilitySetup() {
    dispatch(DeviceActions(DeviceTypes.ToggleGroupVisibilitySetup, null))
  }

  const onSearchReset = () => {
    unsubscribeToValues()
    // if (cancelPendingSubscription) cancelPendingSubscription()
    ongoingSubscriptions.current = []
    dispatch(DeviceActions(DeviceTypes.UntoggleAllDevices, null))
  }

  const dataset = freetextSearch(devices, {
    threshold: 0.25,
    location: 0,
    distance: 81,
    fieldNormWeight: query.length > 1 ? 0.5 : 1,
    ignoreLocation: query.length > 1,
    includeScore: true,
    keys: ["name", "note", "imei", "id"],
  })

  const ongoingSubscriptions = useRef<number[]>([])

  const onSubscribeToSearchResults = useCallback(
    (searchResults) => {
      if (simple) return
      const idMap: number[] = searchResults.map((result) => result.item.id)
      if (!isEqual(idMap, ongoingSubscriptions.current)) {
        ongoingSubscriptions.current = idMap
      }
    },
    [simple]
  )

  const [isReady, cancelPendingSubscription] = useDebounce(
    () => {
      unsubscribeToValues()
      subscribeToSomeDevices(ongoingSubscriptions.current)
    },
    500,
    [ongoingSubscriptions.current]
  )

  useLayoutEffect(() => {
    if (simple) {
      dispatch(DeviceActions(DeviceTypes.SetSyncAddress, { sync: false }))
    } else {
      if (toggledDevices.length > 0)
        dispatch(DeviceActions(DeviceTypes.SetSyncAddress, { sync: true }))
      else dispatch(DeviceActions(DeviceTypes.SetSyncAddress, { sync: false }))
    }
  }, [toggledDevices, simple])

  useLayoutEffect(() => {
    if (simple) {
      unsubscribeToValues()
    }
    return () => {
      searchDispatch(resetSearch())
      unsubscribeToValues()
      appDispatch(
        GlobalAppActions(GlobalAppTypes.ToggleSidebar, { toggled: false })
      )
      // if (cancelPendingSubscription) cancelPendingSubscription()
      ongoingSubscriptions.current = []
    }
  }, [])

  useLayoutEffect(() => {
    forceCheck()
  }, [results])

  const offsetY = getOffsetByRoute()

  return (
    <Responsive
      phone={
        <InitPhoneSearch
          dataset={dataset}
          toggleSidebar={() => toggleSidebar(true)}
          onReset={onSearchReset}
          onFocus={() => toggleSidebar(true)}
          onSubscribe={onSubscribeToSearchResults}
          toggleOnInit={toggleOnInit}
          {...props}
        >
          <StyledPopUnder
            show={sidebarToggled}
            fromBottom
            simple={
              simple || (query.length >= 1 && toggledDevices.length === 0)
            }
            customYOffset={`${offsetY}%`}
          >
            <TileContainer>
              {results.length > 0 && query.length > 0 && (
                <Tile
                  title={`${results.length} ${
                    results.length === 1 ? "resultat" : "resultater"
                  }`}
                >
                  <Group>
                    {results.map((tracker) => {
                      const { id, group } = tracker.item
                      return (
                        <TrackerItem
                          key={id}
                          deviceID={id}
                          groupID={group[0]}
                          simple={simple}
                          device={tracker.item}
                        />
                      )
                    })}
                  </Group>
                </Tile>
              )}
              {results.length === 0 &&
                suggestions.filter(
                  (tracker) =>
                    !(
                      (query.length === 1 && tracker.score > 0.36) ||
                      (query.includes(" ") && tracker.score > 0.36)
                    )
                ).length > 0 &&
                query.length > 0 && (
                  <Tile
                    title={`${
                      suggestions.filter(
                        (tracker) =>
                          !(
                            (query.length === 1 && tracker.score > 0.36) ||
                            (query.includes(" ") && tracker.score > 0.36)
                          )
                      ).length
                    } forslag`}
                  >
                    <Group>
                      {suggestions.map((tracker) => {
                        if (
                          (query.length === 1 && tracker.score > 0.36) ||
                          (query.includes(" ") && tracker.score > 0.36)
                        )
                          return null
                        const { id, group } = tracker.item
                        return (
                          <TrackerItem
                            key={id}
                            deviceID={id}
                            groupID={group[0]}
                            simple={simple}
                            device={tracker.item}
                          />
                        )
                      })}
                    </Group>
                  </Tile>
                )}
              {results.length === 0 &&
                suggestions.length === 0 &&
                query.length > 0 && (
                  <Tile wrap title="Ingen resultater">
                    Prøv et bredere søgeord
                  </Tile>
                )}
              {results.length === 0 && query.length === 0 && (
                <>
                  <Tile
                    onToggle={() => toggleSidebar()}
                    toggled={sidebarToggled}
                    title={
                      <>
                        <StyledTitle>
                          <span tw="opacity-80">Grupper</span>
                        </StyledTitle>
                      </>
                    }
                  >
                    <TrackerGroups simple={simple} />
                  </Tile>

                  {visibilityToggle &&
                  (deviceGroups.length > 1 ||
                    invisibleTrackerGroups.length > 0) ? (
                    <StyledToggle
                      onClick={onToggleVisibilitySetup}
                      toggled={deviceGroupVisibilitySetupToggled}
                    >
                      <IconFlip
                        toggled={deviceGroupVisibilitySetupToggled}
                        iconOff="eye"
                        iconOn="check"
                        size="sm"
                        tw="mr-2"
                      />
                      {`${
                        deviceGroupVisibilitySetupToggled
                          ? "Gem synlige grupper"
                          : `Redigér synlige grupper ${
                              invisibleTrackerGroups.length > 0
                                ? `(${invisibleTrackerGroups.length} skjulte)`
                                : ""
                            }`
                      }`}
                    </StyledToggle>
                  ) : null}
                </>
              )}
            </TileContainer>
          </StyledPopUnder>
        </InitPhoneSearch>
      }
      tabletLandscape={
        <>
          <div tw="px-4 pt-4 bg-white">
            <StyledSearch
              dataset={dataset}
              onSubscribe={onSubscribeToSearchResults}
              onReset={onSearchReset}
              sortFnc={sortFunction}
              withPhoneBorder
              {...props}
            />
          </div>
          {/** When there's search results */}
          {results.length > 0 && query.length > 0 && (
            <Tile
              title={`${results.length} ${
                results.length === 1 ? "resultat" : "resultater"
              }`}
            >
              <Group>
                {results.map((tracker) => {
                  const { id, group } = tracker.item
                  return (
                    <TrackerItem
                      key={id}
                      deviceID={id}
                      groupID={group[0]}
                      simple={simple}
                      device={tracker.item}
                    />
                  )
                })}
              </Group>
            </Tile>
          )}
          {/** When there's suggestions */}
          {results.length === 0 &&
            suggestions.filter(
              (tracker) =>
                !(
                  (query.length === 1 && tracker.score > 0.36) ||
                  (query.includes(" ") && tracker.score > 0.36)
                )
            ).length > 0 &&
            query.length > 0 && (
              <Tile
                title={`${
                  suggestions.filter(
                    (tracker) =>
                      !(
                        (query.length === 1 && tracker.score > 0.36) ||
                        (query.includes(" ") && tracker.score > 0.36)
                      )
                  ).length
                } forslag`}
              >
                <Group>
                  {suggestions.map((tracker) => {
                    if (
                      (query.length === 1 && tracker.score > 0.36) ||
                      (query.includes(" ") && tracker.score > 0.36)
                    )
                      return null
                    const { id, group } = tracker.item
                    return (
                      <TrackerItem
                        key={id}
                        deviceID={id}
                        groupID={group[0]}
                        simple={simple}
                        device={tracker.item}
                      />
                    )
                  })}
                </Group>
              </Tile>
            )}
          {/** When there's no results */}
          {suggestions.length === 0 &&
            results.length === 0 &&
            query.length > 0 && (
              <Tile wrap title="Ingen resultater">
                Prøv et bredere søgeord
              </Tile>
            )}
          {/** Default */}
          {results.length === 0 && query.length === 0 && (
            <>
              <Tile>
                <TrackerGroups
                  simple={simple}
                  hideInvisibleGroups={visibilityToggle}
                />
              </Tile>
              {visibilityToggle &&
              (deviceGroups.length > 1 || invisibleTrackerGroups.length > 0) ? (
                <StyledToggle
                  onClick={onToggleVisibilitySetup}
                  toggled={deviceGroupVisibilitySetupToggled}
                >
                  <IconFlip
                    toggled={deviceGroupVisibilitySetupToggled}
                    iconOff="eye"
                    iconOn="check"
                    size="sm"
                    tw="mr-2"
                  />
                  {`${
                    deviceGroupVisibilitySetupToggled
                      ? "Gem synlige grupper"
                      : `Redigér synlige grupper ${
                          invisibleTrackerGroups.length > 0
                            ? `(${invisibleTrackerGroups.length} skjulte)`
                            : ""
                        }`
                  }`}
                </StyledToggle>
              ) : null}
            </>
          )}
        </>
      }
    />
  )
}

export default React.memo(TrackerSearch)
