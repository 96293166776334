import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
// import styled from "styled-components"
import tw from "twin.macro"
import I18n from "app/I18n"
import {
  ButtonGroup,
  Responsive,
  Button,
  FormField,
  Input,
  ValidationInput,
  Form,
  Tag,
} from "@clevertrack/shared"
import Icon from "lib/Icon"
import { Grid } from "app/Grid"
import { CSVImport } from "app/CSVImport"
import { StyledTile, StyledTileContainer } from "routes/Users"
import AssignTrackers from "app/AssignTrackers"
import PopOver from "app/PopOver"
import { useDevices } from "app/Device/hooks"
import { useFirestoreCompany } from "services/firestore/company"
import UserSelector from "routes/Users/UserSelector"

import { Hint } from "app/Hint"
import { DriverIDHintEnum } from "./driver.types"
import { useUser } from "app/User/hooks"
import Search from "app/Search"
import { SearchContext, SearchProvider } from "app/Search/context"
import { freetextSearch } from "app/Search/helper"

const Drivers: React.FC = ({ children, ...props }) => {
  const { deviceGroups: groups, devices: vehicles } = useDevices(false)
  const [showCSVImport, setShowCSVImport] = useState(false)
  const [showTrackerSelector, setShowTrackerSelector] = useState(false)
  const [selectedDevices, setSelectedDevices] = useState([])
  const [selectedGroups, setSelectedGroups] = useState([])
  const [companyDrivers, setCompanyDrivers] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [currentDriver, setCurrentDriver] = useState<null | {
    [key: string]: any
  }>(null)
  const [isAccessCodeInUse, setIsAccessCodeInUse] = useState(false)
  const formRef = useRef(null)
  const {
    state: { results, suggestions },
  } = useContext(SearchContext)
  const { getUserVisibilitySetting } = useUser()

  const {
    getFirebaseCompanyDrivers,
    saveFirebaseCompanyDrivers,
    saveFirebaseCompanyDriver,
    deleteFirebaseCompanyDriver,
  } = useFirestoreCompany()

  const loadDrivers = async () => {
    const drivers = await getFirebaseCompanyDrivers()
    const mappedDrivers = drivers?.map((x) => {
      return {
        fullName: `${x.firstName} ${x.lastName}`,
        ...x,
      }
    })
    setCompanyDrivers(
      mappedDrivers?.sort((a, b) => (a?.fullName > b?.fullName ? 1 : -1))
    )
  }

  const onNewDriverHandler = (showTrackerSelectorOnNew = true) => {
    setCurrentDriver({})
    setShowForm(true)
    setShowCSVImport(false)
    setShowTrackerSelector(showTrackerSelectorOnNew)
  }

  const onToggleUpload = () => {
    setShowCSVImport((prev) => !prev)
  }

  const onAssignedTrackersChange = (trackers) => {
    const [devices, grps] = trackers
    setSelectedDevices(devices)
    setSelectedGroups(grps)
  }

  const onCloseTrackerSearch = (e) => {
    e.preventDefault()
    setShowTrackerSelector(false)
  }

  const onAcceptHandler = async (data) => {
    const newDrivers = data.map((x) => {
      return {
        ...x,
        groups: selectedGroups.map((x) => x.id),
        devices: selectedDevices.map((x) => x.id),
      }
    })

    await saveFirebaseCompanyDrivers(newDrivers)
    await loadDrivers()
    cancelEdit()
  }

  const onSelectDriver = (driver, mobile) => {
    setCurrentDriver(driver)
    setSelectedGroups(groups.filter((x) => driver.groups.includes(x.id)))
    setSelectedDevices(vehicles.filter((x) => driver.devices.includes(x.id)))
    setShowForm(true)
    if (!mobile) setShowTrackerSelector(true)
  }

  const cancelEdit = () => {
    setCurrentDriver(null)
    setSelectedGroups([])
    setSelectedDevices([])
    setShowForm(false)
    setShowTrackerSelector(false)
    setShowCSVImport(false)
  }

  const saveDriver = async () => {
    if (currentDriver) {
      const result = await saveFirebaseCompanyDriver({
        ...currentDriver,
        groups: selectedGroups.map((x) => x.id),
        devices: selectedDevices.map((x) => x.id),
      })

      if (result === "OK") {
        await loadDrivers()
        cancelEdit()
      }
    }
  }

  const onChangeDriver = (e, key) => {
    if (e.key === 13) {
      e.preventDefault()
      e.stopPropagation()
    }

    if (key === "accesscode") {
      setIsAccessCodeInUse(
        companyDrivers.some((x) => x.accesscode === e.target.value)
      )
    }

    setCurrentDriver((prev) => ({
      ...prev,
      [key]: e.target.value,
    }))
  }

  const onDeleteHandler = async (id) => {
    await deleteFirebaseCompanyDriver(id)
    await loadDrivers()
    cancelEdit()
  }

  const renderCustomTags = (driver) => {
    if (driver.cardID || driver.accesscode) {
      return null
    }
    return <Tag title="Mangler oplysninger" variant="danger" show />
  }

  const onValidateEntry = (entry) => {
    // Checks for duplicates agains existing data
    return companyDrivers.some(
      (x) =>
        x.accesscode === entry.accesscode ||
        x.cardID === entry.cardID ||
        (x.firstName === entry.firstName && x.lastName === entry.lastName)
    )
  }

  const companyDriversDataset = useMemo(() => {
    return freetextSearch(companyDrivers, {
      ignoreLocation: true,
      includeScore: true,
      keys: ["fullName"],
    })
  }, [companyDrivers])

  useEffect(() => {
    if (companyDrivers.length === 0) loadDrivers()
  }, [])

  const renderDriverForm = () => {
    return (
      showForm &&
      currentDriver && (
        <>
          <header tw="block">
            <h3>{currentDriver.id ? "Redigér" : "Ny"} fører</h3>
          </header>
          <Form forwardRef={formRef}>
            <FormField label="Fornavn">
              <Input
                type="text"
                value={currentDriver?.firstName || ""}
                placeholder="Indtast fornavn"
                onChange={(e) => onChangeDriver(e, "firstName")}
                tabIndex="0"
              />
            </FormField>
            <FormField label="Efternavn">
              <Input
                type="text"
                value={currentDriver?.lastName || ""}
                placeholder="Indtast efternavn"
                onChange={(e) => onChangeDriver(e, "lastName")}
                tabIndex="0"
              />
            </FormField>
            <FormField label="Adgangskort">
              <Input
                type="text"
                value={currentDriver?.cardID || ""}
                placeholder="Placér markøren her og scan kortet"
                onChange={(e) => onChangeDriver(e, "cardID")}
                tabIndex="0"
              />
            </FormField>
            <FormField label="Adgangskode" validationKey="accesscode">
              <ValidationInput
                id="accesscode"
                type="text"
                value={currentDriver?.accesscode || ""}
                customValidationMessages={{
                  patternMismatch: "Værdien skal være mellem 0100 og 99999999",
                }}
                minLength="3"
                maxLength="8"
                pattern="([0-9][1-9][0-9]{2,6}|[1-9][0-9]{2,7})"
                placeholder="Indtast pinkode (3 til 8 cifre)"
                onChange={(e) => onChangeDriver(e, "accesscode")}
                tabIndex="0"
              />
            </FormField>
            <small tw="block mt-2 mb-8 text-lg px-4">
              <strong tw="mr-2">Hint:</strong>Adgangskoden kan bruges, hvis ikke
              føreren har et adgangskort
            </small>
            <Hint
              variant="error"
              type={DriverIDHintEnum.AccessCodeInUse}
              toggled={isAccessCodeInUse}
              rememberOnDismiss={false}
              tw="my-4"
            />
            <Responsive
              phone={
                <ButtonGroup sticky="bottom" tw="mt-8 space-x-0">
                  <Button
                    variant="transparent"
                    type="button"
                    tw="text-xl block whitespace-nowrap transition"
                    onClick={cancelEdit}
                    tabIndex="0"
                    size="sm"
                  >
                    <span tw="mr-2">Annullér</span>
                  </Button>
                  <Button
                    variant="primary"
                    type="button"
                    tw="text-xl block whitespace-nowrap transition"
                    disabled={
                      (currentDriver &&
                        currentDriver?.accesscode !== "" &&
                        currentDriver?.accesscode?.length < 3) ||
                      !formRef.current?.checkValidity() ||
                      selectedDevices.length === 0
                    }
                    onClick={saveDriver}
                    tabIndex="0"
                    size="sm"
                  >
                    <span tw="mr-2">Gem fører</span>
                  </Button>
                </ButtonGroup>
              }
              tabletLandscape={
                <ButtonGroup sticky tw="mt-8 px-0 space-x-0">
                  <Button
                    variant="transparent"
                    type="button"
                    tw="text-xl block whitespace-nowrap transition"
                    onClick={cancelEdit}
                    tabIndex="0"
                  >
                    <span tw="mr-2">Annullér</span>
                  </Button>
                  <Button
                    variant="primary"
                    type="button"
                    tw="text-xl block whitespace-nowrap transition"
                    disabled={
                      (currentDriver &&
                        currentDriver?.accesscode !== "" &&
                        currentDriver?.accesscode?.length < 3) ||
                      !formRef.current?.checkValidity() ||
                      selectedDevices.length === 0 ||
                      isAccessCodeInUse
                    }
                    onClick={saveDriver}
                    tabIndex="0"
                  >
                    <span tw="mr-2">Gem fører</span>
                  </Button>
                </ButtonGroup>
              }
            />
          </Form>
        </>
      )
    )
  }

  return (
    <Grid gridConfig="60rem 1.5fr 2fr">
      <div>
        <header tw="hidden md:block">
          <h3>Førere</h3>
          <ButtonGroup position="right" tw="space-x-0">
            <Button
              icon="right"
              size="sm"
              variant="primary"
              onClick={onNewDriverHandler}
            >
              <span>
                <I18n strKey="new" /> fører
              </span>
              <Icon size="sm" icon="plus" />
            </Button>
            <Button
              icon={showCSVImport ? null : "right"}
              size="sm"
              variant={showCSVImport ? "danger" : "default"}
              onClick={onToggleUpload}
            >
              {!showCSVImport ? (
                <>
                  <span>Importer</span>
                  <Icon size="sm" icon="file-import" />
                </>
              ) : (
                <span>Annullér</span>
              )}
            </Button>
          </ButtonGroup>
        </header>
        <div tw="p-4 mt-2 cursor-text">
          <Responsive
            phone={
              <Search
                dataset={companyDriversDataset}
                placeholder="Find en fører"
                withPhoneBorder
                noPad
                tw="md:(p-0 py-4)"
              />
            }
            tabletLandscape={
              <Search
                dataset={companyDriversDataset}
                placeholder="Find en fører"
                noPad
                tw="md:(p-0 py-4)"
              />
            }
          />
        </div>

        <Responsive
          phone={
            <>
              <UserSelector
                users={
                  [...results].length > 0
                    ? [...results].map((x) => x.item)
                    : companyDrivers
                }
                onToggleUser={(driver) => onSelectDriver(driver, true)}
                onDelete={onDeleteHandler}
                customTagsRenderer={renderCustomTags}
                selectedUserID={currentDriver?.id ?? null}
                onCancel={cancelEdit}
                hideTags
              />
            </>
          }
          tabletLandscape={
            <UserSelector
              users={
                [...results].length > 0
                  ? [...results].map((x) => x.item)
                  : companyDrivers
              }
              onToggleUser={(driver) => onSelectDriver(driver, false)}
              onDelete={onDeleteHandler}
              customTagsRenderer={renderCustomTags}
              selectedUserID={currentDriver?.id ?? null}
              onCancel={cancelEdit}
              hideTags
            />
          }
        />
        <ButtonGroup tw="bg-white md:hidden px-8" sticky="bottom">
          <Button
            icon="right"
            variant="primary"
            onClick={() => onNewDriverHandler(false)}
          >
            <span>
              <I18n strKey="new" /> fører
            </span>
            <Icon size="sm" icon="plus" />
          </Button>
        </ButtonGroup>
      </div>

      <div>
        <Responsive
          phone={
            <PopOver show={currentDriver} fromBottom>
              <StyledTileContainer>
                <StyledTile>{renderDriverForm()}</StyledTile>
              </StyledTileContainer>
              <ButtonGroup tw="bg-white md:hidden px-8" sticky>
                <Button
                  variant="default"
                  type="button"
                  onClick={() => setShowTrackerSelector(true)}
                >
                  Tildel enheder
                </Button>
              </ButtonGroup>
            </PopOver>
          }
          tabletLandscape={renderDriverForm()}
        />
        {showCSVImport && (
          <>
            <header tw="hidden md:block">
              <h3>Importér førere</h3>
              <ButtonGroup position="right" tw="space-x-0">
                <Button
                  icon="right"
                  size="sm"
                  variant="transparent"
                  as="a"
                  tw="text-xl block whitespace-nowrap transition hover:(text-brand-500)"
                  href="/import-templates/foerer-id.csv"
                  target="_blank"
                >
                  <span tw="mr-2">Download skabelon</span>
                  <Icon size="sm" icon="download" />
                </Button>
              </ButtonGroup>
            </header>
            <Hint
              type={DriverIDHintEnum.ImportTemplate}
              toggled={true}
              rememberOnDismiss={false}
              tw="mb-8"
            />
            <CSVImport
              onImport={() => setShowTrackerSelector(true)}
              onAccept={onAcceptHandler}
              onCancel={cancelEdit}
              onValidateEntry={onValidateEntry}
              acceptResult={
                <Button
                  variant="primary"
                  tw="text-xl block whitespace-nowrap transition"
                  disabled={selectedDevices.length === 0}
                >
                  <span tw="mr-2">Opret førere</span>
                </Button>
              }
              cancelResult={
                <Button
                  variant="transparent"
                  tw="text-xl block whitespace-nowrap transition"
                >
                  <span tw="mr-2">Annullér</span>
                </Button>
              }
            />
          </>
        )}
      </div>
      <div>
        <Responsive
          phone={
            <PopOver show={showTrackerSelector} fromBottom>
              <StyledTileContainer>
                <StyledTile>
                  <SearchProvider>
                    <AssignTrackers
                      availableGroups={groups}
                      availableTrackers={vehicles}
                      assignedGroups={selectedGroups}
                      assignedTrackers={selectedDevices}
                      onToggleAssignment={onAssignedTrackersChange}
                      hideTitle
                    />
                  </SearchProvider>
                </StyledTile>
              </StyledTileContainer>
              <ButtonGroup tw="mt-16 px-8 bg-white" sticky>
                <Button variant="default" onClick={onCloseTrackerSearch}>
                  <I18n strKey="close" />
                </Button>
              </ButtonGroup>
            </PopOver>
          }
          tabletLandscape={
            <SearchProvider>
              {showTrackerSelector && (
                <AssignTrackers
                  availableGroups={groups}
                  availableTrackers={vehicles}
                  assignedGroups={selectedGroups}
                  assignedTrackers={selectedDevices}
                  onToggleAssignment={onAssignedTrackersChange}
                  hideTitle
                >
                  <header>
                    <h3>{`Tildel enheder & grupper til førere`}</h3>
                  </header>
                </AssignTrackers>
              )}
            </SearchProvider>
          }
        />
      </div>
    </Grid>
  )
}

export { Drivers }
