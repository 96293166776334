import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useContext,
  useLayoutEffect,
  useMemo,
} from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import isEqual from "lodash-es/isEqual"
import "twin.macro"
import GoogleMapReact, { fitBounds } from "google-map-react"
import { ViewportContext } from "context/ViewportContext"
import { MapContext } from "./context"
import { isViewport } from "helpers/viewport"
import {
  createMapOptions,
  getBounds,
  getSinglePoint,
  uxModFitBounds,
} from "./helper"
import { ZonesContext } from "routes/Zones/context"
import { setGoogleMapsInstance } from "./actions"
import tw from "twin.macro"
import { CompanyContext } from "app/Company/context"

const StyledGoogleMapsWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 100;

  .gm-style {
    font: inherit;
  }

  .gm-style-mtc button {
    font: inherit !important;

    &[aria-checked="true"] {
      ${tw`!font-bold`}
    }
  }
`

function GoogleMap({
  maxZoom,
  geoPoints,
  onChange,
  onGoogleApiLoaded,
  omitOnChangeAfterUserInteraction,
  useRawFit,
  customFit,
  otherAnimationsEnded = true,
  forceAnimate,
  toggleUserInteractedOnEvents = ["dragstart", "mouseover"],
  untoggleUserInteractedOnEvents = ["mouseout"],
  toggleZones = false,
  children,
  ...props
}) {
  const userInteractedRef = useRef(false)
  const {
    state: { allowScroll },
    dispatch,
  } = useContext(MapContext)
  const vp = useContext(ViewportContext)
  const mapContainerRef = useRef(null)
  const {
    state: {
      companySettings: { map },
    },
  } = useContext(CompanyContext)

  const [defaultZoom, defaultCenter] = useMemo(() => {
    if (map && map.center)
      return [map.defaultZoom, { lat: map.center[0], lng: map.center[1] }]
    return [
      7,
      {
        lat: 56.0,
        lng: 9.85,
      },
    ]
  }, [map])

  const [zoomAndCenter, setZoomAndCenter] = useState({
    zoom: defaultZoom,
    center: defaultCenter,
  })

  const geoPointIDs = useRef(null)
  const googleMapsRef = useRef<{
    map: google.maps.Map
    maps: typeof google.maps
  } | null>(null)
  const mapOptions = useRef<google.maps.MapOptions | null>()
  const isPhoneOrTablet = isViewport(vp, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"])

  const {
    state: { zones },
  } = useContext(ZonesContext)

  function getZoomAndCenter() {
    if (userInteractedRef.current) return zoomAndCenter
    if (geoPoints.length > 0 && mapContainerRef.current) {
      // First, handle cases where there's only one geoPoint selected.
      if (geoPoints.length === 1) {
        const singlePointCenter = getSinglePoint(geoPoints)
        return {
          center: singlePointCenter,
          zoom: 11,
        }
      } else if (geoPoints.length > 0) {
        // Use helper function to get bounds based on geopoints
        const boundsFromPoints = getBounds(geoPoints)

        if (boundsFromPoints.hasOwnProperty("multibounds")) {
          const { ne, sw, ...bounds } = boundsFromPoints.multibounds
          // Get the size of the map container
          const size = {
            width: mapContainerRef.current.clientWidth,
            height: mapContainerRef.current.clientHeight,
          }

          // Use fitBounds helper function to calculate new bounds and zoom
          const raw_fit = fitBounds(bounds, size)
          // Modify fitBounds output for better UX
          const { center, zoom } = useRawFit
            ? raw_fit
            : uxModFitBounds(
                raw_fit,
                customFit
                  ? customFit
                  : {
                      zoomFactor: isPhoneOrTablet
                        ? 82
                        : forceAnimate
                        ? 87
                        : 100,
                      offsetFactor: isPhoneOrTablet ? [0, 0] : [0, 0],
                    }
              )
          return {
            center,
            zoom: isPhoneOrTablet && !forceAnimate ? zoom + 0.5 : zoom - 1,
          }
        }
        const singlePointCenter = getSinglePoint(geoPoints)
        return {
          center: singlePointCenter,
          zoom: 15,
        }
        // Set new bounds and zoom.
      } else {
        return {
          zoom: defaultZoom,
          center: defaultCenter,
        }
      }
    } else {
      // If no points are selected, show default zoom.
      return {
        center: defaultCenter,
        zoom: defaultZoom,
      }
    }
  }

  function onUpdateZoomAndCenterHandler() {
    const { zoom, center } = getZoomAndCenter()
    if (geoPoints.length === 0) {
      setZoomAndCenter({ zoom: defaultZoom, center: defaultCenter })
    } else {
      setZoomAndCenter({ zoom, center })
    }

    if (isPhoneOrTablet) {
      setZoomAndCenter({ zoom, center })
    }
  }

  function onChangeHandler(options) {
    /* const { zoom, center } = options
    setZoomAndCenter({ zoom: zoom, center: center }) */
    if (onChange && !omitOnChangeAfterUserInteraction) {
      onChange(options)
    } else if (onChange && !userInteractedRef.current) {
      onChange(options)
    }
  }

  const onGoogleApiLoadedHandler = useCallback(
    ({ map, maps }) => {
      if (onGoogleApiLoaded) onGoogleApiLoaded({ map, maps })
      googleMapsRef.current = { map, maps }
      setMapOptions()
    },
    [allowScroll, vp]
  )

  const handleGeopointsUpdate = () => {
    const newIDs = geoPoints.map((p) => p.id)
    if (!isEqual(newIDs, geoPointIDs.current)) {
      geoPointIDs.current = newIDs
      userInteractedRef.current = false
    }
    if (geoPoints.length === 0 && !userInteractedRef.current) {
      if (googleMapsRef.current && googleMapsRef.current.map) {
        googleMapsRef.current.map.panTo(defaultCenter)
        googleMapsRef.current.map.setZoom(defaultZoom)
      }
      setZoomAndCenter({ zoom: defaultZoom, center: defaultCenter })
    } else {
      onUpdateZoomAndCenterHandler()
    }
  }

  // const zoomAndCenter = getZoomAndCenter()

  const renderedZones = useRef([])
  const renderZones = () => {
    if (
      renderedZones.current.length === 0 &&
      googleMapsRef.current?.maps &&
      toggleZones
    ) {
      renderedZones.current = zones
        .filter((zone) => zone.active)
        .map((zone) => {
          const coordinates = zone.Coordinates ?? zone.coordinates
          const z = new googleMapsRef.current.maps.Polygon({
            paths: coordinates,
          })

          z.setMap(googleMapsRef.current.map)
          return z
        })
    } else if (!toggleZones && renderedZones.current.length > 0) {
      renderedZones.current.map((z) => {
        z.setMap(null)
        return null
      })
      renderedZones.current = []
    }
  }

  const eventListeners: google.maps.MapsEventListener[] = []

  const setMapOptions = () => {
    if (
      googleMapsRef.current &&
      googleMapsRef.current.map &&
      googleMapsRef.current.maps
    ) {
      for (const event of toggleUserInteractedOnEvents) {
        eventListeners.push(
          googleMapsRef.current.map.addListener(event, (e) => {
            userInteractedRef.current = true
          })
        )
      }

      for (const event of untoggleUserInteractedOnEvents) {
        eventListeners.push(
          googleMapsRef.current.map.addListener(event, (e) => {
            if (!e.domEvent?.relatedTarget?.className?.includes("gm")) {
              userInteractedRef.current = false
            }
          })
        )
      }

      const customOptions: google.maps.MapOptions = {}

      customOptions.scrollwheel = !isViewport(vp, [
        "PHONE_ONLY",
        "TABLET_PORTRAIT_UP",
      ])
        ? allowScroll
        : false

      mapOptions.current = createMapOptions(vp, customOptions)

      googleMapsRef.current.map.setOptions(mapOptions.current)
    }
  }

  useLayoutEffect(() => {
    handleGeopointsUpdate()
  }, [geoPoints])

  useLayoutEffect(() => {
    if (zones && googleMapsRef.current) renderZones()
  }, [zones, toggleZones, googleMapsRef.current])

  // This useEffect updates the map instance with options.
  useEffect(() => {
    if (googleMapsRef.current && googleMapsRef.current.map) {
      googleMapsRef.current.map.set("scrollwheel", allowScroll)
    }
  }, [googleMapsRef.current, allowScroll, vp])

  useEffect(() => {
    if (googleMapsRef.current && googleMapsRef.current.map) {
      dispatch(
        setGoogleMapsInstance(
          googleMapsRef.current.map,
          googleMapsRef.current.maps
        )
      )
    }
  }, [googleMapsRef.current])

  useEffect(() => {
    return () => {
      if (eventListeners.length > 0) {
        for (const listener of eventListeners) {
          listener.remove()
        }
      }
    }
  }, [])

  return (
    <StyledGoogleMapsWrapper ref={mapContainerRef}>
      <GoogleMapReact
        onGoogleApiLoaded={onGoogleApiLoadedHandler}
        bootstrapURLKeys={{
          key: process.env.GATSBY_MAPS_API_KEY,
          libraries: ["drawing", "geometry"],
          language: "da",
          mapIds: ["49f51d48b88a1ee1"],
        }}
        options={{
          mapId: "49f51d48b88a1ee1",
        }}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        onChange={onChangeHandler}
        center={forceAnimate || isPhoneOrTablet ? zoomAndCenter.center : null}
        zoom={forceAnimate || isPhoneOrTablet ? zoomAndCenter.zoom : null}
        yesIWantToUseGoogleMapApiInternals
        {...props}
      >
        {children}
      </GoogleMapReact>
    </StyledGoogleMapsWrapper>
  )
}

export default GoogleMap

GoogleMap.defaultProps = {
  maxZoom: 18,
  children: null,
  geoPoints: [],
}

GoogleMap.propTypes = {
  children: PropTypes.node,
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  zoom: PropTypes.number,
  maxZoom: PropTypes.number,
  geoPoints: PropTypes.array,
}
