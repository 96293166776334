import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useReport } from "powerbi-report-component"
import "twin.macro"
import { ViewportContext } from "context"
import { isViewport } from "helpers"
import Icon, { IconSizeEnum } from "lib/Icon"
import { Select } from "lib/Select"
import { Logo } from "@clevertrack/shared"

const StyledBIDashboard = styled.div<{ fullscreenEnabled: boolean }>`
  height: 100%;
  width: 100%;
  background: #f5f5f5;
  position: relative;
  overflow: hidden;

  /* .report {
    padding-left: 0.75rem;
    background: rgb(234 234 234);
    height: 100%;
    ${(props) =>
      props.fullscreenEnabled ? `padding-top: 8vh; padding-bottom: 8vh;` : ``}
  } */

  iframe {
    border: none;
  }
  ${(props) => props.theme.media.tablet_landscape_up`
    width: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
  `}
`

const StyledReport = styled.div<{ fullscreenEnabled: boolean }>`
  height: 100%;
  width: 100%;
  position: sticky;
  ${(props) =>
    props.fullscreenEnabled ? `padding-top: 8vh; padding-bottom: 8vh;` : ``}

  ${(props) => props.theme.media.tablet_landscape_up`
    position: static;
    //top: 7.5rem;
    // bottom: 0;
    height: 100%;
    width: 100%;
  `}
`

const StyledLogo = styled(Logo)`
  position: absolute;
  bottom: 4rem;
  right: 4rem;
`

const second = 1000
const minute = second * 60

const frequencyOptions = [
  {
    label: "Ingen",
    value: null,
  },
  {
    label: "Hvert kvarter",
    value: minute * 15,
  },
  {
    label: "Hver halve time",
    value: minute * 30,
  },
  {
    label: "Hver time",
    value: minute * 60,
  },
  {
    label: "Hver 2. time",
    value: minute * 120,
  },
  {
    label: "Hver 3. time",
    value: minute * 180,
  },
  {
    label: "Hver 6. time",
    value: minute * 60 * 6,
  },
  {
    label: "Én gang i døgnet",
    value: minute * 60 * 24,
  },
]

const BIDashboard = ({
  token,
  embed_url,
  report_id,
  title,
  onRefresh,
  onDashboardLoaded,
  ...props
}) => {
  const vp = useContext(ViewportContext)
  const dashboardRef = useRef<null | HTMLElement>(null)
  const [report, setEmbed] = useReport()
  const [updateFrequency, setUpdateFrequency] = useState(null)
  const innerDashboardContainerRef = useRef<HTMLDivElement | null>(null)
  const updateInterval = useRef<null | TimerHandler>(null)
  const [fullscreenToggled, setFullscreenToggled] = useState(false)

  const reportStyles = {
    display: "none",
  }
  const myReportConfig = {
    embedType: "report",
    tokenType: "Embed",
    accessToken: token,
    embedUrl: `${embed_url}`,
    embedId: report_id,
    style: reportStyles,
    extraSettings: {
      localeSettings: {
        language: "da",
        formatLocale: "da",
      },
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
    },
  }

  const myPhoneReportConfig = {
    embedType: "report",
    tokenType: "Embed",
    accessToken: token,
    embedUrl: `${embed_url}`,
    embedId: report_id,
    extraSettings: {
      localeSettings: {
        language: "da",
        formatLocale: "da",
      },
      layoutType: 2,
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
    },
  }

  useEffect(() => {
    // call inside useEffect so the we have the reportRef (reference available)
    isViewport(vp, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"])
      ? setEmbed(innerDashboardContainerRef, myPhoneReportConfig)
      : setEmbed(innerDashboardContainerRef, myReportConfig)
  }, [vp])

  /* useLayoutEffect(() => {
    if (innerDashboardContainerRef.current) {
      const {
        width,
        height,
      } = innerDashboardContainerRef.current.getBoundingClientRect()

      setDesktopLayoutSettings((prev) => ({
        ...prev,
        customLayout: {
          ...prev.customLayout,
          pageSize: {
            ...prev.customLayout.pageSize,
            width,
            height,
          },
        },
      }))
    }
  }, [innerDashboardContainerRef]) */

  const onToggleFullscreen = useCallback(() => {
    if (typeof document !== "undefined") {
      if (document.fullscreenEnabled && dashboardRef.current) {
        if (!fullscreenToggled) {
          dashboardRef.current.requestFullscreen().then(() => {
            setFullscreenToggled(true)
          })
        } else if (document.exitFullscreen) {
          document.exitFullscreen().then(() => {
            setFullscreenToggled(false)
          })
        }
      }
    }
  }, [fullscreenToggled])

  const onTriggerUpdate = async () => {
    await onRefresh()
  }

  const onUpdateFrequencyChange = useCallback((opt) => {
    setUpdateFrequency(opt.value)
    if (opt.value === null) {
      clearInterval(updateInterval.current)
    } else {
      updateInterval.current = setInterval(() => onTriggerUpdate(), opt.value)
    }
  }, [])

  const fullscreenChangeHandler = useCallback(
    (e) => {
      if (!document.fullscreenElement) setFullscreenToggled(false)
    },
    [fullscreenToggled]
  )

  useEffect(() => {
    if (typeof document !== "undefined") {
      document.addEventListener(
        "fullscreenchange",
        fullscreenChangeHandler,
        false
      )
    }
    return () => {
      if (typeof document !== "undefined") {
        document.removeEventListener(
          "fullscreenchange",
          fullscreenChangeHandler,
          false
        )
      }
      if (updateInterval.current) clearInterval(updateInterval.current)
    }
  }, [])

  useEffect(() => {
    if (report) {
      report.on("rendered", onDashboardLoaded)
      // report.addEventListener("rendered", onDashboardLoaded)
    }
  }, [report])

  /* useEffect(() => {
    if (innerDashboardRef.current) {
      innerDashboardRef.current.setAccessToken(token)
    }
  }, [token]) */

  return isViewport(vp, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"]) ? (
    <StyledBIDashboard className="dashboard" {...props}>
      <StyledReport
        className="report"
        ref={innerDashboardContainerRef}
        {...myPhoneReportConfig}
      />
    </StyledBIDashboard>
  ) : (
    <StyledBIDashboard
      className="dashboard"
      ref={dashboardRef}
      fullscreenEnabled={fullscreenToggled}
      {...props}
    >
      <header tw="pt-2 px-8 pl-6 w-full flex items-center text-xl">
        <span tw="cursor-pointer flex items-center transition-all">
          <span tw="mr-2">Automatisk opdatering</span>
          <Select
            value={frequencyOptions.find((x) => x.value === updateFrequency)}
            options={frequencyOptions}
            placeholder="Vælg opdateringsfrekvens"
            onChange={onUpdateFrequencyChange}
            style={{ zIndex: 600, width: "18rem" }}
            customStyles={{
              control: (provided, state) => ({
                ...provided,
                paddingTop: 0,
                minHeight: "3rem",
                borderRadius: 0,
              }),
              indicatorSeparator: (provided, state) => ({
                ...provided,
                display: "none",
              }),
              indicatorsContainer: (provided, state) => ({
                ...provided,
                height: "2.4rem",
                svg: {
                  position: "static",
                  marginTop: ".4rem",
                },
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                paddingTop: 0,
                paddingBottom: 0,
                height: "2.4rem",
              }),
            }}
          />
        </span>
        <span
          tw="ml-auto cursor-pointer flex items-center transition-all hover:(text-brand-500)"
          onClick={onToggleFullscreen}
        >
          <Icon
            icon={`${fullscreenToggled ? "close" : "tv"}`}
            size={IconSizeEnum.SM}
          />
          <span tw="ml-4 inline-block">{`${
            fullscreenToggled ? `Afslut fuldskærm` : `Fuldskærm`
          }`}</span>
        </span>
      </header>
      <StyledReport className="report" ref={innerDashboardContainerRef} />
      {fullscreenToggled && <StyledLogo colored />}
    </StyledBIDashboard>
  )
}

export default BIDashboard

BIDashboard.defaultProps = {}
BIDashboard.propTypes = {
  children: PropTypes.node,
}
