import React, { useContext, useEffect, useMemo } from "react"
import PopOver from "app/PopOver"
import { useUser } from "app/User/hooks"
import { MenuTypeEnum } from "context/App/app.types"
import { AppContext } from "context"
import styled from "styled-components"
import tw from "twin.macro"
import { SizeEnum } from "theme"
import { GlobalAppActions, GlobalAppTypes } from "context/App/actions"
import { Icon } from "@clevertrack/shared"
import { IconSizeEnum } from "lib/Icon"

const StyledPopOver = styled(PopOver)`
  width: 100vw;
  overflow-y: hidden;
  // max-width: 36rem;
  box-shadow: -1px 0px -4px 1px rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 2000;
  bottom: ${SizeEnum.TapBarHeight}rem;

  ${tw`w-full bg-white`}

  .wrapper {
    ${tw`bg-white h-full`}
    display: grid;
    grid-template-rows: ${SizeEnum.Tabs}rem auto;
    overflow-y: hidden;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    left: auto;
    top: ${SizeEnum.DesktopMenu}rem;
    right: 0;
    position: fixed;
    max-width: 40rem;
    bottom: 0;
    z-index: 2000;
  `}

  ${(props) => props.theme.media.desktop_up`
    max-width: 50rem;
  `}

  ${(props) => props.theme.media.xxl_desktop_up`
    max-width: 50rem;

  `}

  ${(props) => props.theme.media.xxxl_desktop_up`
    max-width: 60rem;
  `}
`

export const DeviceInstallation: React.FC = ({ ...props }) => {
  const {
    state: { toggledMenus },
    dispatch,
  } = useContext(AppContext)
  const deviceInstallationToggled = useMemo(
    () => toggledMenus.includes(MenuTypeEnum.DeviceInstallation),
    [toggledMenus]
  )
  const { deviceMountingUrl } = useUser()
  const untoggleInstallation = () =>
    dispatch(
      GlobalAppActions(GlobalAppTypes.ToggleMenu, {
        key: MenuTypeEnum.DeviceInstallation,
      })
    )

  return (
    <StyledPopOver
      selector="#mainContainer"
      fromRight
      show={deviceInstallationToggled}
      zindex={50}
    >
      <span
        tw="absolute right-4 top-4 lg:right-8 cursor-pointer w-8 h-8"
        onClick={untoggleInstallation}
      >
        <Icon icon="close" size={IconSizeEnum.FILL} />
      </span>
      <iframe
        src={deviceMountingUrl}
        frameBorder="0"
        width="100%"
        height="100%"
      />
    </StyledPopOver>
  )
}
