export enum UsersHintEnum {
  MissingDeviceAssignment = "Users/MissingDeviceAssignment",
}

export enum UserTypeEnum {
  CMSADMIN = "Cms Admin",
  ADMIN = "Admin",
  FITTER = "Montør",
  RETAILER = "Forhandler",
  USER = "User",
}
