import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react"
import {
  DrawingManager,
  Polygon,
  GoogleMap,
  useJsApiLoader,
} from "@react-google-maps/api"
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url"
import styled from "styled-components"
import { ICoordinates } from "app/GoogleMap/map.types"
import {
  getCenterFromBounds,
  getBounds,
  getBoundsFromGoogleMapsBounds,
} from "app/GoogleMap/helper"

import { IZone } from "../zones.types"
import { ZonesContext } from "../context"
import { usePointsAndClusters } from "app/GoogleMap/usePointsAndClusters"
import { MapContext } from "app/GoogleMap/context"
import { setGoogleMapsInstance } from "app/GoogleMap/actions"
import { CustomMapsButton } from "lib/CustomMapsButton"
import { IconFlip } from "app/IconFlip"
import { DeviceContext } from "app/Device/context"
import { CompanyContext } from "app/Company/context"

const StyledTrackersToggle = styled(CustomMapsButton)`
  position: absolute;
  z-index: 120;
  top: 6rem;
  margin-top: 0;
  right: 1rem;

  ${(props) => props.theme.media.tablet_landscape_up`
    top: 6rem;
    right: 1rem;
    margin-top: 0;
  `}
`

interface IMapProps {
  zone: ICoordinates[] | null
  viewZone: IZone
  onSetZone: (coords: ICoordinates[]) => void
  zonePolygon: google.maps.Polygon | null
  onSetZonePolygon: (polygon: google.maps.Polygon) => void
}

class Libs {
  static libs: Libraries = ["drawing"]
}

const mapsLoaderOptions = {
  googleMapsApiKey: process.env.GATSBY_MAPS_API_KEY as string,
  libraries: Libs.libs,
  language: "da",
  mapIds: ["49f51d48b88a1ee1"],
}

export const Map: React.FC<IMapProps> | null = React.memo(
  ({
    zone,
    onSetZone,
    zonePolygon,
    onSetZonePolygon,
    viewZone,
    children,
    ...props
  }) => {
    const {
      state: { editZone },
    } = useContext(ZonesContext)
    const [usedZone, setUsedZone] = useState<IZone | null>(null)

    useEffect(() => {
      setUsedZone(viewZone ?? (editZone as IZone))
    }, [editZone, viewZone])

    const { dispatch } = useContext(MapContext)
    const {
      state: {
        companySettings: { map },
      },
    } = useContext(CompanyContext)

    const [defaultZoom, defaultCenter] = useMemo(() => {
      if (map)
        return [map.defaultZoom, { lat: map.center[0], lng: map.center[1] }]
      return [
        7,
        {
          lat: 56.0,
          lng: 9.85,
        },
      ]
    }, [map])
    const [center, setCenter] = useState<ICoordinates>(
      defaultCenter ?? {
        lat: 56.0,
        lng: 9.85,
      }
    )
    const [zoom, setZoom] = useState(defaultZoom)
    const [showTrackers, setShowTrackers] = useState(true)
    const { isLoaded } = useJsApiLoader(mapsLoaderOptions)

    const onPolygonCompleteHandler = (
      polygon: google.maps.Polygon,
      isNew: boolean
    ) => {
      const path: google.maps.MVCArray = polygon.getPath()
      const zoneCoordinates = path
        .getArray()
        .map(({ lat, lng }) => ({ lat: lat(), lng: lng() }))

      if (isNew) zoneCoordinates.push(zoneCoordinates[0])

      if (onSetZonePolygon) onSetZonePolygon(polygon)
      if (onSetZone) onSetZone(zoneCoordinates)
    }

    useEffect(() => {
      if ((usedZone && usedZone.hasOwnProperty("coordinates")) || zone) {
        const z = usedZone as IZone
        const newCenter = getCenterFromBounds(z?.coordinates ?? zone)
        setCenter(newCenter)
        setZoom(13)
      }
      return () => {
        setCenter(
          defaultCenter ?? {
            lat: 56.0,
            lng: 9.85,
          }
        )
        setZoom(defaultZoom ?? 7)
      }
    }, [usedZone, zone, defaultCenter, defaultZoom])

    const onToggleTrackers = () => {
      setShowTrackers((prev) => !prev)
    }

    return isLoaded ? (
      <GoogleMap
        center={center}
        zoom={zoom}
        onLoad={(map) => dispatch(setGoogleMapsInstance(map, google.maps))}
        options={{
          mapId: "49f51d48b88a1ee1",
          gestureHandling: "greedy",
          isFractionalZoomEnabled: false,
          mapTypeControlOptions: {
            mapTypeIds: [
              google.maps.MapTypeId.ROADMAP,
              google.maps.MapTypeId.HYBRID,
            ],
          },
        }}
      >
        <StyledTrackersToggle onClick={onToggleTrackers}>
          <IconFlip
            toggled={showTrackers}
            iconOn="map-marker-alt"
            iconOff="map-marker-alt-slash"
          />
        </StyledTrackersToggle>
        {children}
        {showTrackers && <MapPointsAndClusters />}
        {zone === null ? (
          <DrawingManager
            options={{
              drawingControl: false,
              drawingControlOptions: {
                drawingModes: [google.maps.drawing.OverlayType.POLYGON],
              },
            }}
            drawingMode={google.maps.drawing.OverlayType.POLYGON}
            onPolygonComplete={(polygon) =>
              onPolygonCompleteHandler(polygon, true)
            }
          />
        ) : null}
        {usedZone && usedZone.hasOwnProperty("coordinates") ? (
          <Polygon
            onLoad={(polygon) => onPolygonCompleteHandler(polygon, false)}
            path={(usedZone as IZone)?.coordinates}
            options={{
              zIndex: 1000,
              geodesic: true,
            }}
          />
        ) : null}
      </GoogleMap>
    ) : null
  }
)

const MapPointsAndClusters = () => {
  const {
    state: { devices },
  } = useContext(DeviceContext)

  const {
    state: { mapsInstance },
  } = useContext(MapContext)

  const [bounds, setBounds] = useState<any>(null)
  const [zoom, setZoom] = useState<number>(7)
  const eventListenerRefs = useRef<google.maps.MapsEventListener[]>([])

  const onBoundsChangedHandler = useCallback(() => {
    if (mapsInstance) {
      const newBounds = getBoundsFromGoogleMapsBounds(
        mapsInstance.map.getBounds()
      )
      const newZoom = mapsInstance.map.getZoom()
      setZoom(newZoom)
      setBounds(newBounds.multibounds)
    }
  }, [mapsInstance])

  useEffect(() => {
    if (mapsInstance) {
      setZoom(mapsInstance.map.getZoom())
      eventListenerRefs.current.push(
        mapsInstance.map.addListener("idle", onBoundsChangedHandler)
      )
    }
    return () => {
      if (eventListenerRefs.current && eventListenerRefs.current.length > 0) {
        for (const listener of eventListenerRefs.current) {
          listener.remove()
        }
      }
    }
  }, [mapsInstance])

  const { mapOptions, mappedTrackers } = useMemo(() => {
    const trackers = devices.map(({ id, position, ...rest }) => {
      const modifiedPosition = {
        id,
        ...position,
        properties: rest,
      }
      return modifiedPosition
    })

    const options = {
      bounds: bounds ?? getBounds(trackers)?.multibounds,
      zoom,
    }

    return { mapOptions: options, mappedTrackers: trackers }
  }, [devices, zoom, bounds])

  const { renderPointsAndClusters } = usePointsAndClusters(
    mappedTrackers,
    mapOptions
  )

  return <>{renderPointsAndClusters({ wrapInOverlayView: true })}</>
}
