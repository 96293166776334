import { HintKey } from "app/Hint/hint.types"
import FEATURE, { USERRIGHTS } from "data/featureFlags"
import { useCallback, useContext, useMemo, useState } from "react"
import { useFirestoreUser } from "services/firestore/user"
import { toggleItemInEnumArray } from "utils/array"
import { UserContext } from "./context"
import { UserTypeEnum, UserVisibilitySettings } from "./types"
import { CompanyContext } from "app/Company/context"
import Cookies from "js-cookie"
import useViewport, { ViewportEnum } from "hooks/useViewport"

export const useUser = () => {
  const {
    state: { user, userSettings },
  } = useContext(UserContext)
  const {
    state: { company },
  } = useContext(CompanyContext)
  const { saveFirebaseUser } = useFirestoreUser()
  const [groupTrackerCountMap, setGroupTrackerCountMap] = useState({})
  const viewport = useViewport()

  const getGroupTrackerCountMap = (userTrackers, userGroups) => {
    const userGroupIDs: number[] = userGroups.map((group) => group.id)
    const newGroupTrackerCountMap = {}
    for (const userGroupID of userGroupIDs) {
      newGroupTrackerCountMap[userGroupID] = userTrackers
        .filter((tracker) => tracker.group.includes(userGroupID))
        .length.toString()
    }
    setGroupTrackerCountMap(newGroupTrackerCountMap)
  }

  const resetGroupTrackerCountMap = () => {
    setGroupTrackerCountMap({})
  }

  const toggleUserVisibilitySetting = async (
    key: UserVisibilitySettings | HintKey
  ) => {
    const newVisibilitySettings = toggleItemInEnumArray<UserVisibilitySettings>(
      userSettings?.visibilitySettings || [],
      key
    )
    await saveFirebaseUser({ visibilitySettings: newVisibilitySettings })
  }

  const getUserVisibilitySetting = useCallback(
    (key: UserVisibilitySettings) =>
      userSettings?.visibilitySettings?.includes(key),
    [userSettings?.visibilitySettings]
  )

  const deviceMountingUrl = useMemo(() => {
    if (
      user &&
      userSettings?.userTypes?.includes(UserTypeEnum.Installer) &&
      user.is_token_whitelisted === 1 &&
      company
    ) {
      const strBuilder = [`https://`]
      const token = Cookies.get("clevertrack-authenticated")
      switch (process.env.GATSBY_ACTIVE_ENV) {
        case "production":
          strBuilder.push(
            `hub.clevertrack.dk?token=${token}&companyID=${company.id}`
          )
          break
        case "staging":
          strBuilder.push(
            `hub.staging.clevertrack.dk?token=${token}&companyID=${company.id}`
          )
          break
        case "development":
        default:
          strBuilder.push(
            `localhost:8002?token=${token}&companyID=${company.id}`
          )
      }
      if (viewport !== ViewportEnum.Phone) {
        strBuilder.push(`&disableQR=1`)
      }
      return strBuilder.join("")
    }
    return null
  }, [user, userSettings, company, viewport])

  return {
    groupTrackerCountMap,
    getGroupTrackerCountMap,
    resetGroupTrackerCountMap,
    toggleUserVisibilitySetting,
    getUserVisibilitySetting,
    canWrite: user?.feature.includes(USERRIGHTS.WRITE),
    user,
    userSettings,
    deviceMountingUrl,
  }
}
