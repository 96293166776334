import React, {
  useMemo,
  useState,
  useRef,
  useEffect,
  useContext,
  useLayoutEffect,
} from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Icon from "lib/Icon"
import "twin.macro"
import { Button, ButtonGroup, Loading, Responsive } from "@clevertrack/shared"
import Container from "app/Container"
import PopOver from "app/PopOver"
import { ResultListGroup } from "app/ResultListGroup"
import { ResultList } from "app/ResultListGroup/ResultList"
import Result from "./Result"
import EmptyService from "./EmptyService"
import EditService from "./Panels/EditService"
import SetupForm from "./Panels/SetupForm"
import { criticalSorting } from "./criticalSorting"
import { IntervalModel } from "./Panels/intervalModel"

import ServiceMap from "./ServiceMap"
import {
  getServices,
  createInterval,
  updateInterval,
  deleteInterval,
} from "services/service"
import { useTrackerGroups } from "app/TrackerGroups/hooks"
import { useLockBodyScroll } from "hooks/useLockBodyScroll"
import useViewport, { ViewportEnum } from "hooks/useViewport"
import { SearchContext } from "app/Search/context"
import { DeviceContext } from "app/Device/context"
import Search from "app/Search"
import { freetextSearch } from "app/Search/helper"
import { resetSearch } from "app/Search/actions"

const StyledService = styled(Container)<{ loading: boolean }>`
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 50;
  display: grid;
  grid-template-columns: 1fr;
  padding-top: 0;

  ${(props) => props.theme.media.tablet_landscape_up`
    grid-template-columns: 30rem 1fr;
  `}

  ${(props) => props.theme.media.desktop_up`
    grid-template-columns: 32rem 40rem 1fr;
  `}

  ${(props) => props.theme.media.big_desktop_up`
    grid-template-columns: 32rem 1fr 1fr;
  `}

  ${(props) => props.theme.media.xxl_desktop_up`
    grid-template-columns: 35.5rem 1fr 1fr;
  `}

  ${(props) => props.theme.media.tablet_landscape_up`
      .scrollArea {
        max-height: calc(100vh - 8rem);
        overflow-y: auto;
      }
  `}

  ${(props) => props.theme.media.tablet_landscape_up`
    .tracker-selector {
      position: relative;
      transition: all .2s ease-out;

      &.edit-mode div.item {
        opacity: 0.5;

        &.selected {
          opacity: 1;
          z-index: 100;
        }
      }
    }
  `}
`

const StyledButton = styled(Button)`
  position: absolute;
  z-index: 10000;
  left: 1rem;
  bottom: 8.2rem;
  background: white;
  color: ${(props) => props.theme.colors.secondary};
  box-shadow: ${(props) => props.theme.mapButtonShadow};
`

const TrackerSelector = styled.div`
  &.block-scroll {
    pointer-events: none;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    position: relative;
    overflow: hidden;
    &.block-scroll {
      pointer-events: auto;
    }
  `}
  ${(props) => props.theme.media.desktop_up`
    border-right: ${(props) => `1px solid ${props.theme.colors.grayLight}`};
  `}
`

const StyledPopOver = styled(PopOver)`
  top: 0;
  right: 0;
  left: 0;
  height: calc(100vh);
  overflow-y: scroll;
  position: fixed;
  ${(props) => props.theme.media.tablet_landscape_up`
    position: sticky;
    bottom: 0;
 `}

  ${(props) => props.theme.media.desktop_up`
    position: absolute;
    bottom: auto;
  `}
`

const PropertiesArea = styled.div`
  position: relative;
  overflow: hidden;
`

const Sidebar = styled.div`
  background: ${(props) => props.theme.colors.grayLighter};
  border-right: 1px solid ${(props) => props.theme.colors.grayLight};
`

const NoUI = styled.p`
  font-size: 80%;
  font-weight: bold;
  color: ${(props) => props.theme.colors.gray};
  opacity: 0.5;
`

const Title = styled.div`
  padding-bottom: 3.2rem;
  h3 {
    margin-bottom: 0;
  }
`

const SubHeading = styled.small`
  color: ${(props) => props.theme.colors.gray};
  font-weight: 600;
  opacity: 0.6;
  margin-top: 0;
`

const BackButton = ({ onClick, ...props }) => {
  return (
    <StyledButton onClick={onClick} icon="left" size="sm" invert>
      <Icon size="sm" icon="long-arrow-left" />
      <span>Tilbage</span>
    </StyledButton>
  )
}

function Service({ user, children, ...props }) {
  const [toggled, setToggled] = useState({})
  const [selected, setSelected] = useState({})
  const [interval, setInterval] = useState({})
  const [showMap, setShowMap] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showSetup, setShowSetup] = useState(false)
  const [setupParams, setSetupParams] = useState("new") // can also be "edit", statelifting from /Panels/EditService.jsx
  const vp = useViewport()
  useLockBodyScroll(
    [ViewportEnum.Phone, ViewportEnum.TabletPortrait].includes(vp) &&
      (showMap || showEdit || showSetup)
  )

  const {
    state: { results, suggestions, query },
    dispatch: searchDispatch,
  } = useContext(SearchContext)
  const {
    state: { devices },
  } = useContext(DeviceContext)

  const allResults = [...results, ...suggestions].map(({ item }) => item.id)

  const [setupTrackerData, setSetupTrackerData] = useState(undefined)

  const [serviceData, setServiceData] = useState(null)

  const { getInvisibleTrackers } = useTrackerGroups()

  const objectByString = (o, s) => {
    s = s.replace(/\[(\w+)\]/g, ".$1") // convert indexes to properties
    s = s.replace(/^\./, "") // strip a leading dot
    var a = s.split(".")
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i]
      if (k in o) {
        o = o[k]
      } else {
        return
      }
    }
    return o
  }

  const sortObjectDecending = (input, sortKey) =>
    Object.assign([], input).sort((a, b) => {
      const aKey = objectByString(a, sortKey)
      const bKey = objectByString(b, sortKey)
      return bKey > aKey ? 1 : aKey > bKey ? -1 : 0
    })

  const sortTrackerIntervalsByServiceIndex = (input) =>
    input.map((tracker) =>
      Object.assign(Object.assign({}, tracker), {
        intervals: sortObjectDecending(tracker.intervals, "index"),
      })
    )

  const fetchData = async () => {
    try {
      const response = await getServices()
      if (response.data.result === "OK") {
        const { vehicles: serviceTrackers } = response.data
        const trackers = serviceTrackers.map((t) => {
          const mergeWithDeviceInfo = devices.find((d) => d.id === t.id)
          return {
            ...t,
            deviceMeta: mergeWithDeviceInfo,
          }
        })
        const invisibleTrackers = getInvisibleTrackers()
        const sortedTrackers = await sortTrackerIntervalsByServiceIndex(
          trackers.filter((tracker) => !invisibleTrackers.includes(tracker.id))
        )

        setServiceData(sortedTrackers)
        if (!!selected.id) {
          const newSelected = trackers.find((x) => x.id === selected.id)
          setSelected(newSelected)
          if (newSelected.intervals.length < 1) {
            cancelHandler()
          }
        }
        return
      } else {
        return console.log("ERROR")
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (devices.length > 0) {
      fetchData()
    }
  }, [devices.length])

  useEffect(() => {
    // Toggled to list tl
    const tl = Object.keys(toggled)
    const id = tl[tl.length - 1]
    if (!!id) {
      setSelected(serviceData.find((x) => x.id.toString() === id))
    } else {
      // should be empty object, but quick hack
      setSelected({ name: "enhed" })
    }
  }, [toggled])

  // useEffect(() => {
  //   console.log("SELECTED:", selected)
  // }, [selected])

  const cancelHandler = () => {
    setShowSetup(false)
    setShowEdit(false)
    setToggled({})
  }

  const onClickHandler = (id) => {
    if (toggled[id]) {
      cancelHandler()
    } else {
      setShowEdit(true)
      setShowSetup(false)
      setToggled({ [id]: true })
    }
  }

  const missingClickHandler = (tracker) => {
    const { id } = tracker
    if (toggled[id]) {
      cancelHandler()
    } else {
      const currentTracker = serviceData.find((x) => x.id === id)
      // setInterval(new IntervalModel({ startValue: currentTracker.mileage }))
      setInterval(new IntervalModel({ startValue: null }))
      setSetupParams("new")
      setShowSetup(true)
      setShowEdit(false)
      setToggled({ [id]: true })
      setSetupTrackerData(tracker)
    }
  }

  const onCreateHandler = async (newInterval, id) => {
    try {
      const response = await createInterval(id, newInterval)
      // console.log("CREATE:", response)
      await fetchData()
      setShowSetup(false)
      setTimeout(() => {
        setShowEdit(true)
      }, 100)
    } catch (err) {
      console.log(err)
    }
  }

  const onUpdateHandler = async (updatedInterval) => {
    const { id } = updatedInterval
    try {
      if (id) {
        await updateInterval(id, updatedInterval)
      }
      // console.log("UPDATE:", response)
      await fetchData()
      setShowSetup(false)
    } catch (err) {
      console.log(err)
    }
  }

  const onDeleteHandler = async (id) => {
    try {
      const response = await deleteInterval(id)
      // console.log("DELETE:", response)
      await fetchData()
      setShowSetup(false)
    } catch (err) {
      console.log(err)
    }
  }

  const onExitHandler = () => {
    setShowSetup(false)
    if (selected.intervals < 1) {
      setToggled({})
      setShowEdit(false)
    }
  }

  const seperateData = (input) => {
    const out = { criticals: [], nonCriticals: [], missing: [] }
    if (!!input) {
      input.map((item) => {
        const [topInterval] = item.intervals
        if (!!topInterval) {
          const { index: service_index } = topInterval
          if (service_index >= criticalSorting[0]) out.criticals.push(item)
          else out.nonCriticals.push(item)
        } else {
          out.missing.push(item)
        }
      })
    }
    return out
  }

  const sortData = (input) =>
    Object.assign(
      {},
      ...Object.entries(input).map((entry) => {
        const [key, value] = entry
        if (key !== "missing") {
          const sortedValue = sortObjectDecending(value, "intervals[0].index")
          return { [key]: sortedValue }
        } else {
          return { [key]: value }
        }
      })
    )

  const { criticals, nonCriticals, missing } = useMemo(
    () => sortData(seperateData(serviceData)),
    [serviceData]
  )

  // const { criticals, nonCriticals, missing } = useMemo(
  //   () => seperateData(serviceData),
  //   [serviceData]
  // )

  const renderResults = (title, subHeading, trackers) => {
    return (
      <ResultList
        title={
          <Title>
            <h3>{title}</h3>
            <SubHeading>{subHeading}</SubHeading>
          </Title>
        }
      >
        {trackers.length > 0 ? (
          <>
            {trackers.map((tracker, i) => {
              if (query.length > 0 && !allResults.includes(tracker.id))
                return null
              return (
                <Result
                  key={`${title}_${i}`}
                  tracker={tracker}
                  onClick={() => onClickHandler(tracker.id)}
                  toggle={toggled[tracker.id]}
                  className={`item${toggled[tracker.id] ? " selected" : ""}`}
                />
              )
            })}
          </>
        ) : (
          <NoUI>Ingen trackere</NoUI>
        )}
      </ResultList>
    )
  }

  const renderNonCriticals = () =>
    renderResults("Service OK", "Index: 0-69 %", nonCriticals)
  const renderCriticals = () =>
    renderResults("Kommende service", "Index: 70-100+ %", criticals)

  const renderEditService = () => {
    return (
      <EditService
        data={selected}
        onCancel={cancelHandler}
        showSetup={setShowSetup}
        setSetupParams={setSetupParams}
        setInterval={setInterval}
        fetch={fetchData}
        onUpdateInterval={onUpdateHandler}
        user={user}
      />
    )
  }

  const renderSetupForm = () => {
    return (
      <SetupForm
        key={selected.id}
        data={selected}
        setupParams={setupParams}
        onExit={onExitHandler}
        interval={interval}
        onCreate={onCreateHandler}
        onUpdate={onUpdateHandler}
        onDelete={onDeleteHandler}
        setupTrackerData={selected}
      />
    )
  }

  const propertyAreaRef = useRef(null)
  const paId = "___propertiesArea"
  const trackerSelectorRef = useRef(null)
  const tsId = "___TrackerSelector"

  const dataset = freetextSearch([...criticals, ...nonCriticals, ...missing], {
    threshold: 0.25,
    location: 0,
    distance: 81,
    fieldNormWeight: query.length > 1 ? 0.5 : 1,
    ignoreLocation: query.length > 1,
    includeScore: true,
    keys: ["name", "note"],
  })

  return (
    <>
      <StyledService loading={devices.length === 0} {...props}>
        <Responsive
          tabletLandscape={
            <Sidebar className="scrollArea">
              <EmptyService
                missing={missing}
                onClick={missingClickHandler}
                toggled={toggled}
              >
                <div tw="mb-14 mt-8 pb-1">
                  <Search noPad withPhoneBorder dataset={dataset} />
                </div>
              </EmptyService>
            </Sidebar>
          }
          phone={
            devices.length > 0 ? (
              <div tw="mt-8 p-4 px-8">
                <Search noPad withPhoneBorder dataset={dataset} />
              </div>
            ) : (
              <div tw="h-screen">
                <Loading tw="static" />
              </div>
            )
          }
        />
        <TrackerSelector
          id={tsId}
          ref={trackerSelectorRef}
          className={`scrollArea tracker-selector${
            showEdit ? " edit-mode" : ""
          }${showMap || showEdit || showSetup ? " block-scroll" : ""}`}
        >
          <Responsive
            largeDesktop={
              devices.length === 0 ? (
                <Loading />
              ) : (
                <ResultListGroup tw="px-8 py-4" columns={2}>
                  <div>{renderNonCriticals()}</div>
                  <div>{renderCriticals()}</div>
                </ResultListGroup>
              )
            }
            tabletLandscape={
              devices.length === 0 ? (
                <Loading />
              ) : (
                <ResultListGroup tw="px-8 py-4" columns={1}>
                  {renderCriticals()}
                  <ButtonGroup position="center">
                    <Button variant="primary" onClick={() => setShowMap(true)}>
                      Se på kort
                    </Button>
                  </ButtonGroup>
                  {renderNonCriticals()}
                </ResultListGroup>
              )
            }
            phone={
              devices.length === 0 ? null : (
                <ResultListGroup tw="px-8 py-4" columns={1}>
                  {renderCriticals()}
                  <ButtonGroup position="center">
                    <Button variant="primary" onClick={() => setShowMap(true)}>
                      Se på kort
                    </Button>
                  </ButtonGroup>
                  {renderNonCriticals()}
                  <EmptyService
                    missing={missing}
                    onClick={missingClickHandler}
                    toggled={toggled}
                  />
                </ResultListGroup>
              )
            }
          />
        </TrackerSelector>
        <Responsive
          desktop={
            <>
              <PropertiesArea id={paId} ref={propertyAreaRef}>
                <ServiceMap points={criticals} />
              </PropertiesArea>
              {!!propertyAreaRef.current && (
                <>
                  <StyledPopOver
                    selector={`#${paId}`}
                    show={showEdit}
                    fromLeft
                    zindex={200}
                  >
                    {renderEditService()}
                  </StyledPopOver>
                  <StyledPopOver
                    selector={`#${paId}`}
                    show={showSetup}
                    fromBottom
                    zindex={300}
                  >
                    {renderSetupForm()}
                  </StyledPopOver>
                </>
              )}
            </>
          }
          tabletLandscape={
            <>
              {!!trackerSelectorRef.current && (
                <>
                  <StyledPopOver
                    selector={`#${tsId}`}
                    show={showEdit}
                    fromRight
                    zindex={200}
                  >
                    {renderEditService()}
                  </StyledPopOver>
                  <StyledPopOver
                    selector={`#${tsId}`}
                    show={showSetup}
                    fromBottom
                    zindex={300}
                  >
                    {renderSetupForm()}
                  </StyledPopOver>
                  <StyledPopOver selector={`#${tsId}`} show={showMap} fromRight>
                    <BackButton onClick={() => setShowMap(false)} />
                    <ServiceMap points={criticals} />
                  </StyledPopOver>
                </>
              )}
            </>
          }
          phone={
            <>
              {!!trackerSelectorRef.current && (
                <>
                  <StyledPopOver show={showEdit} fromRight>
                    {renderEditService()}
                  </StyledPopOver>
                  <StyledPopOver show={showSetup} fromRight>
                    {renderSetupForm()}
                  </StyledPopOver>
                  <StyledPopOver show={showMap} fromRight>
                    <BackButton onClick={() => setShowMap(false)} />
                    <ServiceMap points={criticals} />
                  </StyledPopOver>
                </>
              )}
            </>
          }
        />
      </StyledService>
    </>
  )
}

export default Service

Service.defaultProps = {}
Service.propTypes = {
  children: PropTypes.node,
}
