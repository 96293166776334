import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import cogoToast from "@clevertrackdk/cogo-toast"
import Container from "app/Container"
import { getDashboards } from "services/dashboards"
import BIDashboard from "./BIDashboard"
import tw from "twin.macro"
import { Loading } from "@clevertrack/shared"

const StyledDashboard = styled(Container)`
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 50;
  background: rgb(234 234 234);
`

const StyledBIDashboard = styled(BIDashboard)<{ loading: boolean }>`
  ${tw`transition-all`}
  ${(props) => (props.loading ? `opacity: 0;` : `opacity: 1;`)}
`

function Dashboard({ children, ...props }) {
  const [dashboard, setDashboard] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  async function getDashboardsAsync() {
    try {
      setIsLoading(true)
      const dashboardsResponse = await getDashboards()
      if (dashboardsResponse.data.result === "OK") {
        const {
          embedToken,
          embedURL,
          reportID,
          reportTitle,
          ...rest
        } = dashboardsResponse.data.reports[0]
        const db = {
          token: embedToken,
          embed_url: embedURL,
          report_id: reportID,
          title: reportTitle,
          ...rest,
        }
        setDashboard(db)
        return true
      } else {
        setIsLoading(false)
        cogoToast.error(`Kunne ikke indlæse dashboardet`)
        return false
      }
    } catch (error) {
      setIsLoading(false)
      cogoToast.error("Kunne ikke indlæse dashboardet")
      return false
    }
  }

  useEffect(() => {
    if (!dashboard) getDashboardsAsync()
  }, [])

  const onDashboardLoaded = useCallback(
    (event) => {
      if (event.type === "rendered" && isLoading) {
        setIsLoading(false)
      }
    },
    [isLoading]
  )

  return (
    <StyledDashboard {...props}>
      {dashboard && dashboard.hasOwnProperty("token") ? (
        <>
          {isLoading && <Loading loadingText="Indlæser dashboard" />}
          <StyledBIDashboard
            loading={isLoading}
            onRefresh={getDashboardsAsync}
            onDashboardLoaded={onDashboardLoaded}
            {...dashboard}
          />
        </>
      ) : null}
    </StyledDashboard>
  )
}

export default Dashboard

Dashboard.defaultProps = {}
Dashboard.propTypes = {
  children: PropTypes.node,
}
