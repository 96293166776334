import React from "react"
import { Tooltip as DefaultTooltip } from "react-tooltip"
import { Icon } from "@clevertrack/shared"
import styled from "styled-components"
import { IconSizeEnum } from "lib/Icon"
import tw from "twin.macro"

const StyledTooltip = styled(DefaultTooltip)`
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
  ${tw`text-brand-black-base`}
  &.tooltip {
    color: #333;
    background: #fff;
    font-size: 1.4rem;
    max-width: 80vw;
    z-index: 100;

    ${(props) => props.theme.media.tablet_landscape_up`
      max-width: 60rem;
    `}

    .arrow {
    }
  }
`

type TooltipType = {
  content: any
  icon?: string
  tooltipKey: string
}

export const Tooltip: React.FC<TooltipType> = ({
  content,
  icon = "info-circle",
  tooltipKey,
  ...props
}) => {
  /* const [showTooltipID, setShowTooltipID] = useState<string | null>(null)
  const featuresRef = useRef(null)

  useOnClickOutside(featuresRef, () => {
    setShowTooltipID(null)
  })

  const onClickTooltipHandler = (e, key) => {
    featuresRef.current = e.target
    setShowTooltipID(key)
  } */

  return (
    <>
      <span tw="relative" {...props}>
        <Icon
          id={`tooltip_${tooltipKey}`}
          icon={icon}
          size={IconSizeEnum.FILL}
          // onClick={(e) => onClickTooltipHandler(e, `tooltip_${key}`)}
          tw="cursor-pointer outline-0"
        />
        <StyledTooltip
          anchorSelect={`#tooltip_${tooltipKey}`}
          className="tooltip"
          classNameArrow="arrow"
          opacity={1.0}
          positionStrategy="fixed"
          // isOpen={showTooltipID === `tooltip_${key}`}
          content={content}
        />
      </span>
    </>
  )
}
