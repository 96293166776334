import React, { useState, useEffect, useCallback, useMemo } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import "twin.macro"
import { useParams } from "@reach/router"
import { Card, Button, Responsive, ButtonGroup } from "@clevertrack/shared"
import cogoToast from "@clevertrackdk/cogo-toast"
import Icon from "lib/Icon"
import Container from "app/Container"
import { getReports } from "services/reports"

import Report from "./Report"

import DateComponent from "app/Date"
import { Tooltip } from "app/Tooltip"

const StyledReports = styled(Container)`
  height: 100%;
  width: 100%;

  /* position: absolute;
  top: 0;
  bottom: 0;
  z-index: 50; */

  iframe {
    border: none;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  h3 {
    margin: 0;
  }

  p {
    margin: 0;
    font-size: 0.8em;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 0;
    height: 100%;
  `}
`

const StyledReportsWrapper = styled.div`
  display: grid;
  padding: 2rem;
  ${(props) => props.theme.media.tablet_landscape_up`
    grid-template-columns: repeat(auto-fit, 30rem);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    padding: 2rem;
  `}
`

const StyledReportWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
  max-width: 100vw;
  padding-bottom: 4rem;
  ${(props) => props.theme.media.tablet_landscape_up`

  `}
`

const StyledButton = styled(Button)``

const StyledMiniReport = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  cursor: pointer;

  .img-wrapper {
    overflow: hidden;
    height: 15rem;
    img {
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }

  h4 {
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
    font-size: 0.8em;
  }

  a {
    color: ${(props) => props.theme.colors.primary};
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    flex: 0 1 30rem;

    & + & {
      margin-top: 0;
    }
  `}
`

function Reports({ ...props }) {
  const [selectedReport, setSelectedReport] = useState(null)
  const [availableReports, setAvailableReports] = useState([])
  const [currentFilters, setCurrentFilters] = useState(null)
  const [loading, setLoading] = useState(false)
  const [currentReport, setCurrentReport] = useState<any | null>(null)
  const params = useParams()

  const getAvailableReports = async () => {
    setLoading(true)
    try {
      const reportsResponse = await getReports()
      if (reportsResponse && reportsResponse.data.result === "OK") {
        if (params.reportId) {
          findAndSetSelectedReport(
            reportsResponse.data.reports,
            params.reportId
          )
        }
        setAvailableReports(reportsResponse.data.reports)
        setLoading(false)
      } else {
        cogoToast.error(`Could not load reports at this time`)
        setLoading(false)
      }
    } catch (error) {
      cogoToast.error("Could not load reports at this time")
      setLoading(false)
    }
  }

  const findAndSetSelectedReport = useCallback(
    (matchId) => {
      const report = availableReports.find((r) => r.id === matchId)
      if (report) {
        const {
          embedToken,
          embedURL,
          reportID,
          reportTitle,
          reportText,
          lastRefresh,
          hideLastRefresh,
        } = report
        handleSelectReport(
          embedToken,
          embedURL,
          reportID,
          reportTitle,
          reportText,
          lastRefresh,
          hideLastRefresh
        )
      }
    },
    [availableReports]
  )

  const onBackButtonHandler = () => {
    navigate(`/app/reports`)
  }

  const handleSelectReport = (
    token,
    embed_url,
    report_id,
    title,
    text,
    lastRefresh,
    hideLastRefresh,
    id = null
  ) => {
    if (!selectedReport) {
      setSelectedReport({
        token,
        embed_url,
        report_id,
        title,
        text,
        lastRefresh,
        hideLastRefresh,
      })
    }
    if (id) navigate(`/app/reports/${id}`)
  }

  useEffect(() => {
    if (!currentReport) getAvailableReports()
  }, [currentReport])

  useMemo(() => {
    if (params.reportId === "" && selectedReport !== null) {
      setSelectedReport(null)
      return
    }
    if (params.reportId !== "" && selectedReport === null) {
      findAndSetSelectedReport(params.reportId)
    }
  }, [params, selectedReport])

  const onReportRendered = useCallback(async () => {
    if (currentReport) {
      /* console.log(currentReport)
      const filters = await currentReport.getFilters()
      console.log(filters) */
    }
  }, [currentReport])

  useEffect(() => {
    if (currentReport) {
      currentReport.on("rendered", onReportRendered)
      // report.addEventListener("rendered", onDashboardLoaded)
    }
  }, [currentReport])

  const onReportLoadedHandler = (report) => {
    setCurrentReport(report)
  }

  const renderLastUpdated = () => {
    return (
      selectedReport &&
      !selectedReport.hideLastRefresh && (
        <span tw="flex items-center space-x-2 text-lg">
          <span>Sidste opdatering: </span>
          <DateComponent date={selectedReport.lastRefresh} />{" "}
          <Tooltip
            content="Bemærk: alle data opdateres hver halv time. Dette inkludere også tilføjelse, ændring eller sletning af enhedsnavne, grupper eller zoner."
            tooltipKey="lastUpdated"
            tw="h-6 w-6"
            icon="question-circle"
          />
        </span>
      )
    )
  }

  return selectedReport === null ? (
    <StyledReports>
      <Responsive
        tabletLandscape={
          <>
            <header tw="py-4 px-8">
              <h2 tw="my-0">Rapporter</h2>
            </header>
          </>
        }
      />
      <StyledReportsWrapper>
        {!loading &&
          availableReports.map((report) => {
            const {
              embedToken,
              embedURL,
              reportID,
              reportTitle,
              reportText,
              reportImage,
              lastRefresh,
              hideLastRefresh,
              id,
            } = report
            return (
              <StyledMiniReport
                key={id}
                size="sm"
                onClick={() =>
                  handleSelectReport(
                    embedToken,
                    embedURL,
                    reportID,
                    reportTitle,
                    reportText,
                    lastRefresh,
                    hideLastRefresh,
                    id
                  )
                }
              >
                <div className="img-wrapper">
                  <img src={reportImage} alt={reportText} />
                </div>
                <h4>{reportTitle}</h4>
                <p>{reportText}</p>
                <a>Se rapport</a>
              </StyledMiniReport>
            )
          })}
      </StyledReportsWrapper>
    </StyledReports>
  ) : (
    <StyledReports {...props}>
      <div tw="py-4 pl-6 pr-8">
        <header>
          <h3 tw="mt-0">{selectedReport.title}</h3>
          <div tw="flex items-center">
            <ButtonGroup tw="p-0 m-0">
              {currentReport && (
                <StyledButton
                  tw="hidden md:(block ml-4 text-brand-black-base font-normal)"
                  variant="default"
                  invert
                  icon="left"
                  size="sm"
                  onClick={() => currentReport.print()}
                >
                  <Icon size="sm" icon="print" />
                  <span>Udskriv</span>
                </StyledButton>
              )}
              <StyledButton
                onClick={onBackButtonHandler}
                icon="left"
                size="sm"
                variant="cancel"
                tw="text-brand-black-base font-normal"
              >
                <Icon size="sm" icon="long-arrow-left" />
                <span>Tilbage</span>
              </StyledButton>
            </ButtonGroup>
          </div>
        </header>
        <header>
          <div tw="text-2xl md:(flex items-baseline justify-between w-full)">
            <span tw="block">{selectedReport.text}</span>
            <span>{renderLastUpdated()}</span>
          </div>
        </header>
      </div>
      <StyledReportWrapper>
        <Report onReportLoaded={onReportLoadedHandler} {...selectedReport} />
      </StyledReportWrapper>
    </StyledReports>
  )
}

export default Reports
