import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import { Grid } from "app/Grid"
import I18n from "app/I18n"
import { Button, ButtonGroup, Responsive } from "@clevertrack/shared"
import Icon from "app/Icon"
import "twin.macro"

import { CreateZoneForm } from "./components/CreateZoneForm"
import { Map } from "./components/Map"
import { IZone, ZoneFormActionsEnum } from "./zones.types"
import { ICoordinates } from "app/GoogleMap/map.types"
import { ZoneSelector } from "./components/ZoneSelector"
import { useZones } from "./hooks"
import { ZonesContext } from "./context"
import { ZonesActions, ZonesTypes } from "./actions"
import { Flow, IScreen } from "app/Flow"
import PopOver from "app/PopOver"

export enum ZoneScreens {
  LIST = "list",
  DRAW = "draw",
  COMPLETE = "complete",
}

const StyledGoogleMapsWrapper = styled.div`
  height: calc(100vh - 9.8rem);
  padding: 0;
  top: 3.1rem;
  position: relative;

  > div {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 100;
  }

  .untoggle-route-map {
    position: absolute;
    z-index: 10000;
    top: 6rem;
    left: 1rem;
    background: white;
    color: ${(props) => props.theme.colors.secondary};
    box-shadow: ${(props) => props.theme.mapButtonShadow};
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    height: 100%;
    padding: 0;
    top: 0;
  `}
`

const StyledCompletionWrapper = styled.div`
  height: 100%;
  padding-top: 3.1rem;

  ${(props) => props.theme.media.tablet_landscape_up`
    padding-top: 0;
    padding-bottom: 0;
  `}
`

const StyledButtonGroup = styled(ButtonGroup)`
  position: absolute;
  left: 1rem;
  bottom: 2.4rem;
  right: 10rem;
  z-index: 1000;

  box-shadow: ${(props) => props.theme.mapButtonShadow};
`

export const Zones: React.FC<{}> = React.memo(({ ...props }) => {
  const {
    dispatch,
    state: { zones, editZone },
  } = useContext(ZonesContext)

  const [zone, setZone] = useState<ICoordinates[] | null>(null)
  const [formToggled, setFormToggled] = useState<boolean>(false)
  const [zonePolygon, setZonePolygon] = useState<google.maps.Polygon | null>(
    null
  )
  const [currentScreen, setCurrentScreen] = useState<ZoneScreens | null>(
    ZoneScreens.LIST
  )

  const { getAllZones, createNewZone, updateZone } = useZones()

  const onZoneSaveHandler = async (action: ZoneFormActionsEnum) => {
    const payload: IZone = {
      ...editZone,
      active: editZone.active,
      coordinates: zone,
    }

    try {
      let result: string
      switch (action) {
        case ZoneFormActionsEnum.CREATE:
          result = await createNewZone(payload)
          break
        case ZoneFormActionsEnum.SAVE:
          result = await updateZone(payload)
          break
      }
      // Getting to here means either action were successful. Let's reset the form.
      if (result !== "error") {
        dispatch(ZonesActions(ZonesTypes.ResetZoneForm, null))
        setFormToggled(false)
        onZoneResetHandler()
      }
    } catch (error) {
      // Getting down here means something went wrong. Don't do anything with the form.
    }
  }

  const onZoneResetHandler = () => {
    setZone(null)
    zonePolygon?.setMap(null)
    setZonePolygon(null)
  }

  const onNewZoneHandler = () => {
    setFormToggled(true)
    setZone(null)
    zonePolygon?.setMap(null)
    setZonePolygon(null)
    dispatch(ZonesActions(ZonesTypes.ResetZoneForm, null))
  }

  const onResetHandler = () => {
    setFormToggled(false)
    setZone(null)
    dispatch(ZonesActions(ZonesTypes.ResetZoneForm, null))
  }

  useEffect(() => {
    if (zones.length === 0) {
      getAllZones()
    }
  }, [zones])

  const renderMobile = () => {
    const onNewZoneMobileHandler = () => {
      onNewZoneHandler()
      setCurrentScreen(ZoneScreens.DRAW)
    }

    const onSubmitZoneMobileHandler = (args) => {
      onZoneSaveHandler(args).then((x) => {
        setCurrentScreen(ZoneScreens.LIST)
      })
    }

    const onCancelZoneMobileHandler = () => {
      setCurrentScreen(ZoneScreens.LIST)
    }

    const onEditZoneMobileHandler = () => {
      setCurrentScreen(ZoneScreens.DRAW)
    }

    const onBackHandler = () => {
      onNewZoneHandler()
      setCurrentScreen(ZoneScreens.LIST)
    }

    const screenSet: IScreen[] = [
      {
        key: "list",
        children: (
          <ZoneSelector
            onNewZone={onNewZoneMobileHandler}
            onEditZone={onEditZoneMobileHandler}
          />
        ),
        wrapper: <div tw="h-full pb-32" />,
      },
      {
        key: "draw",
        children: (
          <StyledGoogleMapsWrapper>
            <Map
              zone={zone}
              zonePolygon={zonePolygon}
              onSetZonePolygon={(polygon) => setZonePolygon(polygon)}
              onSetZone={(coords) => setZone(coords)}
            >
              <Button
                className="untoggle-route-map"
                onClick={onBackHandler}
                icon="left"
                size="sm"
                tw="m-0"
              >
                <Icon size="sm" icon="long-arrow-left" />
                <span>Tilbage</span>
              </Button>

              <StyledButtonGroup position="between" tw="bg-white py-2 px-4">
                <Button
                  size="sm"
                  invert
                  variant="danger"
                  onClick={onZoneResetHandler}
                >
                  Nulstil
                </Button>
                <Button
                  size="sm"
                  variant="primary"
                  disabled={!(zone && zone.length > 0)}
                  onClick={() => setCurrentScreen(ZoneScreens.COMPLETE)}
                >
                  Fortsæt
                </Button>
              </StyledButtonGroup>
            </Map>
          </StyledGoogleMapsWrapper>
        ),
        wrapper: (
          <PopOver fromRight show={currentScreen === ZoneScreens.DRAW} />
        ),
      },
      {
        key: "complete",
        children: (
          <StyledCompletionWrapper tw="bg-white h-full">
            <header tw="pt-8 px-8">
              <h3>
                <I18n strKey={`${editZone?.id ? "edit" : "create"}`} />{" "}
                <I18n strKey="zone" />
              </h3>
            </header>
            <CreateZoneForm
              onSubmit={onSubmitZoneMobileHandler}
              onCancel={onCancelZoneMobileHandler}
              canSubmit={zone && zone.length > 0}
            />
          </StyledCompletionWrapper>
        ),
        wrapper: (
          <PopOver fromRight show={currentScreen === ZoneScreens.COMPLETE} />
        ),
      },
    ]
    return <Flow screenSet={screenSet} />
  }

  const renderDesktop = () => {
    return (
      <Grid gridConfig="60rem 1.5fr 2fr">
        <div>
          <ZoneSelector
            editing={formToggled}
            onNewZone={onNewZoneHandler}
            onEditZone={() => setFormToggled(true)}
            onDelete={() => onResetHandler()}
            onCancel={() => onResetHandler()}
          />
        </div>
        {formToggled ? (
          <>
            <div>
              <header>
                <h3>
                  <I18n strKey={`${editZone?.id ? "edit" : "create"}`} />{" "}
                  <I18n strKey="zone" />
                </h3>
              </header>
              <CreateZoneForm
                onSubmit={onZoneSaveHandler}
                onCancel={() => onResetHandler()}
                canSubmit={zone && zone.length > 0}
              />
              <header>
                <h3 tw="my-0">
                  <I18n strKey="zone" />
                </h3>
              </header>
              <section tw="text-2xl">
                {zone === null ? (
                  <>
                    <p tw="text-2xl">Sådan tegner du en zone:</p>
                    <ol tw="space-y-4">
                      <li>
                        Zoom ind på det område, hvor du vil tegne en zone.
                      </li>
                      <li>Klik et sted på kortet, for at påbegynde zonen.</li>
                      <li>Afslut din zone, ved samme punkt som du begyndte.</li>
                    </ol>
                  </>
                ) : (
                  <Button variant="danger" onClick={onZoneResetHandler}>
                    Nulstil zone
                  </Button>
                )}
              </section>
            </div>
            <StyledGoogleMapsWrapper>
              <Map
                zone={zone}
                zonePolygon={zonePolygon}
                onSetZonePolygon={(polygon) => setZonePolygon(polygon)}
                onSetZone={(coords) => setZone(coords)}
              />
            </StyledGoogleMapsWrapper>
          </>
        ) : null}
      </Grid>
    )
  }

  return (
    <Responsive
      phone={<Grid>{renderMobile()}</Grid>}
      tabletLandscape={renderDesktop()}
    />
  )
})
